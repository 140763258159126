<script setup lang="ts">
import { useClientStore } from "@/stores/clientStore";

import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import PhoneIcon from "@/components/icons/PhoneIcon.vue";

const clientStore = useClientStore();

const phoneNumber = clientStore.phoneNumber;
</script>

<template>
  <div v-if="clientStore.client !== 'rus'" class="flex justify-center">
    <div
      class="flex flex-col md:flex-row items-center md:border-2 border-grayDetails-100 rounded-full gap-y-2"
    >
      <ExclamationCircleIcon
        class="w-8 h-8 md:w-14 md:h-14 2xl:w-16 2xl:h-16 rotate-180 md:m-1 text-grayDetails-100"
      />
      <h3
        class="hidden md:block text-grayDetails-200 md:text-sm 2xl:text-base font-montserrat"
      >
        ANTE CONSULTAS PODÉS COMUNICARTE CON NOSOTROS AL
      </h3>
      <pre
        class="md:hidden text-center text-grayDetails-200 text-xs font-montserrat"
      >
      ANTE CONSULTAS PODÉS
      COMUNICARTE CON NOSOTROS AL</pre
      >
      <div class="flex items-center">
        <phone-icon
          style="width: 12px; height: 12px"
          class="md:ml-3 text-link w-3 h-3"
        />
        <span
          class="text-link font-bold ml-1 md:mr-11 text-xs md:text-sm 2xl:text-base font-montserrat"
        >
          {{ phoneNumber }}</span
        >
      </div>
    </div>
  </div>
</template>
