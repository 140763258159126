import { axiosInstance as axios } from "./axios.config";

export interface VideoCall {
  link: string;
}

export const getVideoCallUrl = async (params: {
  policyId: number;
  coverageId: number;
}): Promise<string> => {
  try {
    const response = await axios.get<VideoCall>(
      `/policy_medical_room/${params.policyId}/request_video_call/?coverage_id=${params.coverageId}`
    );

    const data = response.data;
    return data.link as string;
  } catch (error) {
    throw new Error("Error al cargar la url de la videollamada");
  }
};

export type TelemedicineDetail = {
  id: number;
  cant: number;
  events_per_month: number;
  available_cant: number;
  available_events_per_month: number;
};

export const getTelemedicineInfo = async (params: {
  policyId: number;
  coverageId: number;
}): Promise<TelemedicineDetail> => {
  try {
    const response = await axios.get<TelemedicineDetail>(
      `/policy_medical_room/${params.policyId}/available_events/?coverage_id=${params.coverageId}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error al cargar el detalle de telemedicina");
  }
};
