import { ref } from "vue";
import {
  getTelemedicineInfo,
  getVideoCallUrl,
  type TelemedicineDetail,
} from "../services/telemedicine.services";

const telemedicineModal = ref(false);
const videoCallModal = ref(false);
const detail = ref<TelemedicineDetail | null>(null);
const link = ref<string | null>(null);
const loadingVideoCall = ref(false);
const linkNotFound = ref(false);
const currentPolicyId = ref();
const currentCoverageId = ref();

export const useTelemedicine = () => {
  async function openTelemedicineModal(policyId: number, coverageId: number) {
    currentPolicyId.value = policyId;
    currentCoverageId.value = coverageId;

    detail.value = await getTelemedicineInfo({ policyId, coverageId });
    telemedicineModal.value = true;
  }

  function closeTelemedicineModal() {
    telemedicineModal.value = false;
    detail.value = null;
    linkNotFound.value = false;
    currentCoverageId.value = null;
    currentPolicyId.value = null;
  }

  return {
    telemedicineModal,
    detail,
    openTelemedicineModal,
    closeTelemedicineModal,
  };
};

export const useVideoCall = () => {
  async function initVideoCall() {
    if (!currentCoverageId.value || !currentPolicyId.value) return;

    loadingVideoCall.value = true;
    linkNotFound.value = false;

    try {
      link.value = await getVideoCallUrl({
        policyId: currentPolicyId.value,
        coverageId: currentCoverageId.value,
      });

      if (link.value === null) {
        linkNotFound.value = true;
        loadingVideoCall.value = false;
        return;
      }

      telemedicineModal.value = false;
      window.open(link.value, "_blank");
    } catch (error) {
      console.log(error);
    } finally {
      loadingVideoCall.value = false;
    }
  }

  function closeVideoCallModal() {
    videoCallModal.value = false;
    link.value = null;
  }

  return {
    videoCallModal,
    loadingVideoCall,
    link,
    linkNotFound,
    initVideoCall,
    closeVideoCallModal,
  };
};
