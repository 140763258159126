import { axiosInstance as axios } from "./axios.config";
import type { Notification } from "@/stores/notifications";
import type { Request } from "@/interfaces/product.interface";

export const fetchNotifications = async (): Promise<{
  data: Notification[];
}> => {
  return await axios.get("/notifications/");
};

export const fetchNotificationsByRequest = async (
  requestId: Request["id"]
): Promise<{ data: Notification[] }> => {
  return await axios.get(`/notifications/?request_id=${requestId}`);
};

export const fetchUnreadNotificationsByRequest = async (
  requestId: Request["id"]
): Promise<{ data: Notification[] }> => {
  return await axios.get(`/notifications/?request_id=${requestId}&read=false`);
};

export const markAsRead = async (notificationId: number): Promise<string> => {
  return await axios.post(`/notifications/${notificationId}/mark_as_read/`);
};

export const markAllAsRead = async (
  requestId?: Request["id"]
): Promise<string> => {
  const url = requestId
    ? `/notifications/mark_as_read_all/?request_id=${requestId}`
    : "/notifications/mark_as_read_all/";

  return await axios.post(url);
};

// Push Notification
interface SubscribeToken {
  device: string;
  token_fcm: string;
}

export const sendTokenFcm = async (params: SubscribeToken): Promise<void> => {
  const url = import.meta.env.VITE_SUBSCRIBE_FCM_URL;
  return await axios.post(url, params);
};
