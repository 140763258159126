<template>
  <div class="flex flex-col w-full p-0">
    <div class="relative group">
      <select
        class="base-input text-grayDetails-400 w-full text-xs md:text-sm 2xl:text-base"
        :id="id"
        :name="name"
        :value="inputValue"
        @change="handleChange"
      >
        <option value="" disabled selected>{{ placeholder }}</option>
        <option
          v-for="(item, idx) of items"
          :key="idx"
          :value="item.value"
          :selected="item.value === inputValue"
        >
          {{ item.text }}
        </option>
      </select>

      <div
        class="absolute inset-y-0 right-0 flex items-center pointer-events-none"
      >
        <ChevronRightIcon
          class="h-5 w-5 mx-3 text-grayDetails-100 group-active:rotate-90 duration-300"
        />
      </div>
    </div>

    <BaseErrorMessage
      :id="id ? id : name"
      class="font-medium font-montserrat text-xs md:text-sm 2xl:text-base"
      v-show="errorMessage && meta.touched"
    >
      {{ errorMessage }}
    </BaseErrorMessage>
  </div>
</template>

<script setup lang="ts">
import type { VeeItem } from "@/interfaces/Inputs";
import { useField } from "vee-validate";
import { toRef, type PropType } from "vue";
import ChevronRightIcon from "../../icons/ChevronRightIcon.vue";

const props = defineProps({
  id: { type: String, default: "" },
  name: { type: String, required: true },
  placeholder: { type: String, default: "" },
  items: {
    type: Array as PropType<VeeItem[]>,
    default: () => {
      return [];
    },
  },
  value: { type: String, default: "" },
});

const name = toRef(props, "name");

const {
  value: inputValue,
  handleChange,
  errorMessage,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<style scoped>
select {
  background: none;
}
</style>
