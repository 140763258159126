<script setup lang="ts">
import { FieldArray, useField } from "vee-validate";

import { useClientStore } from "@/stores/clientStore";

import TextInput from "./TextInput.vue";
import TextareaInput from "./TextareaInput.vue";
import { XIcon } from "@heroicons/vue/solid";
import VeeSelect from "./VeeSelect.vue";
import VeeDate from "./VeeDate.vue";
import { computed, ref, watch } from "vue";
import { useRequestStore } from "@/stores/requestStore";
import { useProductStore } from "@/stores/productStore";
import { storeToRefs } from "pinia";

const clientStore = useClientStore();
const reqStore = useRequestStore();
const productStore = useProductStore();
const { insuredInfo } = storeToRefs(productStore);

const rangeOptions = [
  { text: "Mañana (08:00 - 12:00)", value: "morning" },
  { text: "Tarde (12:00 - 16:00)", value: "afternoon" },
  { text: "Noche (16:00 - 20:00)", value: "evening" },
];

const NOW = "Lo antes posible";
const SELECT = "Elegir fecha y hora";

const availabilityOptions = [NOW, SELECT];
const optionSelected = ref(availabilityOptions[0]);
const showEmergencyOptions = ref(false);

const { value: disponibilidad } = useField("availability");
const { value: urgency } = useField("is_urgency");

type DataParams = {
  isUrgency: boolean;
  availability: Availability[] | undefined;
};

type Availability = {
  date: Date | null;
  range: string;
};

function setData(params: DataParams) {
  urgency.value = params.isUrgency;
  disponibilidad.value = params.availability;
}

watch(
  optionSelected,
  (option) => {
    if (option === SELECT) {
      setData({ isUrgency: false, availability: [{ date: null, range: "" }] });
    }

    if (option === NOW) {
      setData({ isUrgency: true, availability: undefined });
    }
  },
  { immediate: true }
);

const isEmergency = computed(() => {
  return reqStore.emergencyService;
});

watch(
  isEmergency,
  (emergency) => {
    if (emergency) {
      showEmergencyOptions.value = true;
    } else {
      showEmergencyOptions.value = false;
      setData({ isUrgency: false, availability: [{ date: null, range: "" }] });
    }
  },
  {
    immediate: true,
  }
);

const showSelect = computed(() => {
  return (
    (isEmergency.value && optionSelected.value === SELECT) || !isEmergency.value
  );
});

const today = computed(() => {
  return new Date().toISOString().split("T")[0];
});

const clientTheme = clientStore.theme;
</script>

<template>
  <h1 class="title my-3 text-base md:text-lg 2xl:text-xl">¿Dónde y cuándo?</h1>
  <div
    class="grid grid-cols-1 md:grid-cols-2 border-section py-4 2xl:py-8 px-4 md:px-8 2xl:px-12 gap-x-9"
    :class="clientTheme"
  >
    <div class="flex flex-col gap-3 2xl:gap-6">
      <TextInput
        label="Provincia"
        name="province"
        type="text"
        class="title text-xs md:text-sm 2xl:text-base font-medium cursor-not-allowed"
        read-only
      />

      <!-- Mobile -->
      <TextInput
        name="city"
        label="Localidad"
        read-only
        class="md:hidden title text-xs font-medium cursor-not-allowed"
        id="mobile_city"
      />
      <!-- end -->

      <TextInput
        name="street_name"
        type="text"
        label="Calle"
        class="title text-xs md:text-sm 2xl:text-base font-medium"
        :read-only="!!insuredInfo.street_name"
      />

      <!-- Mobile -->
      <TextInput
        name="street_number"
        label="Número"
        type="text"
        class="md:hidden title text-xs font-medium"
        id="mobile_number"
        :read-only="!!insuredInfo.street_number"
      />

      <div class="md:hidden flex gap-x-2">
        <TextInput
          name="floor"
          label="Piso"
          hint="(opcional)"
          type="text"
          class="md:hidden title text-xs font-medium"
          id="mobile_floor"
          :read-only="!!insuredInfo.floor"
        />

        <TextInput
          name="department"
          label="Depto"
          hint="(opcional)"
          type="text"
          class="md:hidden title text-xs font-medium"
          id="mobile_department"
          :read-only="!!insuredInfo.department"
        />
      </div>
      <!-- End -->

      <template v-if="showEmergencyOptions">
        <h3 class="title text-sm 2xl:text-base">Disponibilidad</h3>

        <base-radio-group
          name=""
          vertical
          :options="availabilityOptions"
          v-model="optionSelected"
        />
      </template>

      <FieldArray
        v-if="showSelect"
        name="availability"
        v-slot="{ fields, push, remove }"
        class="flex flex-col"
      >
        <div>
          <span
            v-show="!showEmergencyOptions"
            class="title text-sm 2xl:text-base"
            >Disponibilidad</span
          >

          <fieldset
            v-for="(field, idx) of fields"
            :key="field.key"
            class="flex gap-x-2 md:gap-5 mb-4 md:mb-2 2xl:mb-4"
          >
            <div class="w-1/2 md:w-full">
              <VeeDate
                class="text-grayDetails-400 text-xs md:text-sm 2xl:text-base"
                :id="`date_${idx}`"
                :min="today"
                :name="`availability[${idx}].date`"
              />
            </div>

            <div class="w-1/2 md:w-full">
              <div class="flex">
                <VeeSelect
                  :name="`availability[${idx}].range`"
                  :id="`range_${idx}`"
                  placeholder="Rango"
                  :items="rangeOptions"
                />

                <button
                  v-if="idx > 0"
                  type="button"
                  class="text-skin-xicon ml-1 flex"
                  @click="idx > 0 ? remove(idx) : null"
                >
                  <XIcon class="w-6 pt-[0.4rem] md:pt-[0.6rem]" />
                </button>
              </div>
            </div>
          </fieldset>
        </div>

        <button
          v-if="fields.length <= 2"
          class="btn btn-salmon btn-transition h-11 bg-skin-major hover:bg-skin-button-bg-hover my-0 py-2 px-4 border-2"
          @click="push({ date: '', range: '' })"
          type="button"
        >
          Agregar otra disponibilidad
        </button>
      </FieldArray>
    </div>

    <div class="flex flex-col gap-3 2xl:gap-6">
      <TextInput
        name="city"
        label="Localidad"
        read-only
        class="hidden md:block title text-sm 2xl:text-base font-medium cursor-not-allowed"
      />

      <div class="flex gap-4">
        <TextInput
          name="street_number"
          label="Número"
          type="text"
          class="hidden md:block title text-sm 2xl:text-base font-medium"
          :read-only="!!insuredInfo.street_number"
        />

        <TextInput
          name="floor"
          label="Piso"
          hint="(opcional)"
          type="text"
          class="hidden md:block title text-sm 2xl:text-base font-medium"
          :read-only="!!insuredInfo.floor"
        />

        <TextInput
          name="department"
          label="Depto"
          hint="(opcional)"
          type="text"
          class="hidden md:block title text-sm 2xl:text-base font-medium"
          :read-only="!!insuredInfo.department"
        />
      </div>

      <div class="flex flex-col">
        <TextareaInput
          name="comment"
          label="Contanos qué sucedió"
          class="text-xs md:text-sm 2xl:text-base"
          placeholder="Ejemplo: La llave de mi casa se rompió cuando estaba dentro de la cerradura y no logro destrabar la puerta."
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
