<script setup lang="ts">
import type { MessagePayload } from "@firebase/messaging";
import { XIcon } from "@heroicons/vue/solid";
defineProps<{
  notification: MessagePayload;
  visible: boolean;
}>();

const emits = defineEmits<{
  (e: "closeNotification"): void;
}>();

function closeNotification() {
  emits("closeNotification");
}
</script>

<template>
  <div v-if="visible" class="z-50 fixed top-24 right-0 left-0 w-[90%] mx-auto">
    <div
      class="alert flex items-center justify-between border-2 rounded-xl border-blueNav-100 mx-auto py-3 px-4 min-w-[20rem] max-w-fit bg-blue-50"
    >
      <div class="flex flex-col text-grayDetails-400">
        <h3 class="font-semibold text-lg">
          {{ notification.notification?.title }}
        </h3>
        <p class="font-medium text-base">
          {{ notification.notification?.body }}
        </p>
      </div>
      <button
        type="button"
        class="cursor-pointer text-grayDetails-200 mb-auto"
        @click="closeNotification"
      >
        <XIcon
          class="w-7 transition-all rounded-full p-1 hover:bg-gray-200 duration-300 text-[#D23232]"
        />
      </button>
    </div>
  </div>
</template>

<style scoped>
.alert {
  animation: show_slide 1s ease forwards;
}
@keyframes show_slide {
  0% {
    transform: translateY(-100%);
  }
  40% {
    transform: translateY(-10%);
  }
  80% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-10px);
  }
}
</style>
