<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { useClientStore } from "@/stores/clientStore";

import type { Request } from "@/interfaces/product.interface";

import RequestSearchBar from "./RequestSearchBar.vue";
import RequestSearchBarMobile from "./RequestSearchBarMobile.vue";
import TableRowItem from "./TableRowItem.vue";
import RowCard from "./RowCard.vue";

const props = defineProps<{
  requests: Request[];
  filters?: object;
  title?: number | string;
}>();

const route = useRoute();
const emits = defineEmits(["set-filters"]);

const clientStore = useClientStore();

const requestPath = computed(() => {
  return route.name === "Requests" ? true : false;
});

const coverageText = computed(() => {
  if (clientStore.client !== "rapihogar") {
    return "Asistencias";
  }
  return "Solicitudes";
});

function search(e: Event) {
  const search = (e.target as HTMLInputElement).value;
  emits("set-filters", {
    ...props.filters,
    search,
  });
}

function searchByStatus(e: Event) {
  const status = (e.target as HTMLInputElement).value;
  emits("set-filters", {
    ...props.filters,
    status,
  });
}

function filterDate(date1: HTMLInputElement, date2: HTMLInputElement) {
  const startDate = date1.value;
  const endDate = date2.value;

  emits("set-filters", {
    ...props.filters,
    startDate,
    endDate,
  });
}
</script>

<template>
  <div v-if="requestPath">
    <RequestSearchBar
      class="hidden md:flex"
      @search="search"
      @search-status="searchByStatus"
      @filter-date="filterDate"
    />
  </div>

  <div class="md:hidden" v-if="requestPath">
    <RequestSearchBarMobile @search-status="searchByStatus" />
  </div>

  <section class="mt-2 md:mt-8">
    <h3
      class="title mb-2 text-[15px] md:text-lg 2xl:text-xl py-4"
      v-if="route.name === 'ProductView'"
    >
      {{ coverageText }} {{ title }}
    </h3>

    <div class="md:hidden mb-4" v-for="request of requests" :key="request.id">
      <RowCard
        v-if="requestPath"
        :request="request"
        :request-path="requestPath"
      />
    </div>

    <article
      :class="{ 'hidden md:block;': requestPath }"
      class="md:block border-section p-2 md:px-8 md:py-6"
    >
      <table class="table w-full table-fixed border-collapse">
        <thead>
          <tr>
            <th class="md:w-3/6 md:ml-40">N° de gestión</th>
            <th class="hidden md:table-cell w-2/6">Tipo</th>
            <th v-if="requestPath" class="hidden md:table-cell w-3/6">
              Producto
            </th>
            <th class="hidden md:table-cell w-2/6">Póliza</th>
            <th class="hidden md:table-cell w-2/6">Fecha de inicio</th>
            <th class="md:hidden text-xs w-1/4">Fecha</th>
            <th class="w-1/4 md:w-2/6">Estado</th>
            <th class="md:table-cell w-3/12 md:w-2/6">Ver detalle</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="request of requests" :key="request.id">
            <TableRowItem :request="request" :request-path="requestPath" />
          </template>
        </tbody>
      </table>
    </article>
  </section>
</template>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
}
@media (max-width: 600px) {
  table th {
    font-size: 12px;
  }
}
</style>
