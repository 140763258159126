<script setup lang="ts">
import { ref } from "vue";
import ExpandIcon from "@/components/icons/ExpandIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";

const emits = defineEmits<{
  (e: "search", event: Event): void;
  (e: "search-status", event: Event): void;
  (e: "filter-date", date1: HTMLInputElement, date2: HTMLInputElement): void;
}>();

const status = [
  { id: 1, name: "En curso" },
  { id: 2, name: "Realizado" },
  { id: 3, name: "Cancelado" },
];

const dateFrom = ref<string>("");
const dateFromType = ref<string>("text");
const dateToType = ref<string>("text");

const setElementType = (evt: Event, type: string) => {
  const event = evt.target as HTMLInputElement;

  if (event.value) return;

  if (event.id === "start") {
    dateFromType.value = type;
  }
  if (event.id === "end") {
    dateToType.value = type;
  }
};

function searchByStatus(evt: Event) {
  emits("search-status", evt);
}

const debounceSearch = debounce(function (evt: Event) {
  emits("search", evt);
}, 500);

function debounce(cb: any, delay = 1000) {
  let timeout: any;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

function filterDate() {
  const date1 = document.getElementById("start") as HTMLInputElement;
  const date2 = document.getElementById("end") as HTMLInputElement;
  emits("filter-date", date1, date2);
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex flex-grow-2">
      <div class="min-w-[229px]">
        <p class="text-sm 2xl:text-base title">Estado</p>
        <div class="relative group">
          <select
            @change="searchByStatus($event)"
            class="bg-white border border-grayDetails-100 text-grayDetails-100 rounded-md shadow-sm cursor-pointer py-2 2xl:py-[10px] appearance-none leading-tight focus:border-[3px] focus:ring-0 focus:border-grayDetails-200 text-sm 2xl:text-base font-montserrat"
          >
            <option value="">Seleccionar estado</option>
            <option
              v-for="item of status"
              :key="item.id"
              :class="[
                item.id === 1 ? 'text-yellowStatus font-semibold' : '',
                item.id === 2 ? 'text-greenStatus-200 font-semibold' : '',
                item.id === 3 ? 'text-danger font-semibold' : '',
              ]"
            >
              {{ item.name }}
            </option>
          </select>
          <div
            class="absolute inset-y-0 left-36 2xl:left-40 flex items-center pointer-events-none"
          >
            <ChevronRightIcon
              class="h-[24px] w-[24px] mx-3 text-grayDetails-100 group-active:rotate-90 duration-300"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <p class="text-sm 2xl:text-base title">Fecha</p>
        <div class="flex">
          <div class="relative">
            <input
              @blur="setElementType($event, 'text')"
              @change="filterDate()"
              class="mr-3 date-input"
              @focus="setElementType($event, 'date')"
              id="start"
              placeholder="Desde"
              :type="dateFromType"
              v-model="dateFrom"
            />
            <div
              class="absolute inset-y-0 left-32 flex items-center pointer-events-none"
            >
              <ExpandIcon
                v-show="dateFromType === 'text'"
                class="h-5 w-5 mx-3 text-grayDetails-100"
              />
            </div>
          </div>

          <div class="relative">
            <input
              @blur="setElementType($event, 'text')"
              class="date-input"
              @change="filterDate()"
              @focus="setElementType($event, 'date')"
              id="end"
              :min="dateFrom ? dateFrom : undefined"
              placeholder="Hasta"
              :type="dateToType"
            />
            <div
              class="absolute inset-y-0 left-32 flex items-center pointer-events-none"
            >
              <ExpandIcon
                v-show="dateToType === 'text'"
                class="h-5 w-5 mx-3 text-grayDetails-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-grow pt-3">
      <div class="relative mt-[10px]">
        <BaseInput
          @keyup="debounceSearch($event)"
          type="text"
          placeholder="Buscar"
          class="pr-3 pl-10 py-2 2xl:py-[10px] bg-white border border-grayDetails-100 rounded-md cursor-pointer placeholder-grayDetails-100 font-montserrat text-sm 2xl:text-base"
        />
        <div
          class="absolute inset-y-0 left-11 flex items-center pointer-events-none"
        >
          <SearchIcon class="h-6 w-6 mx-3 text-grayDetails-100" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
}
input[type="date"] {
  color: #c2c2c2;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  z-index: 10;
  display: block;
  width: 20px;
  margin: 0px;
  padding: 0px;
  height: 20px;
  opacity: 1;
  cursor: pointer;
  filter: invert(0.8);
}
</style>
