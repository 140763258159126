import { createApp } from "vue";
import { createPinia } from "pinia";
import VueRecaptcha from "vue3-recaptcha-v2";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import "@/assets/tailwind.css";
import "@/assets/animate.css";
import VueSplide from "@splidejs/vue-splide";
import { i18n } from "./plugins/i18n";

const app = createApp(App).use(i18n);

const components = import.meta.globEager("./components/*.vue");

Object.entries(components).forEach(([path, definition]) => {
  const componentName =
    path
      ?.split("/")
      ?.pop()
      ?.replace(/\.\w+$/, "") || "";

  app.component(componentName, definition.default);
});

Sentry.init({
  app,
  environment: import.meta.env.VITE_SENTRY_ENV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `${import.meta.env.VITE_SENTRY_PROJECT ?? "portal"}@${
    import.meta.env.VITE_SENTRY_RELEASE ?? "0.0.0"
  }`,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
    }),
  ],
  trackComponents: true,
  debug: import.meta.env.VITE_SENTRY_ENV !== "production",
  tracesSampleRate: import.meta.env.VITE_SENTRY_ENV === "production" ? 0.5 : 1,
  replaysOnErrorSampleRate: 1,
  logErrors: import.meta.env.VITE_SENTRY_ENV !== "production",
});

app.use(VueRecaptcha, {
  siteKey:
    import.meta.env.VITE_RECAPTCHA_SITE_KEY ||
    "6LdWEsIeAAAAABRpq2OEbJs1jljlw5bL_XXRwbW2",
  alterDomain: false,
});

app.use(createPinia());
app.use(router);
app.use(VueSplide);

router.isReady().then(() => {
  app.mount("#app");
});
