<script setup lang="ts">
import { useField, useForm } from "vee-validate";
import { ref } from "vue";
import { object, string } from "yup";

import { useAuthStore } from "@/stores/authStore";
import { useClientStore } from "@/stores/clientStore";

import ReCAPTCHA from "../../ReCAPTCHA.vue";

const authStore = useAuthStore();

const errorMessage = ref("");

const clientStore = useClientStore();

const validationSchema = object({
  name: string().required("Debe seleccionar una opción"),
  address: string().required("Debe seleccionar una opcición"),
  recaptcha: string().required("Debe marcar la casilla"),
});

const { handleSubmit, errors } = useForm({
  validationSchema,
});

const { value: name } = useField("name");
const { value: address } = useField("address");
const { value: recaptcha } = useField("recaptcha");

const callbackVerify = (token: string): void => {
  recaptcha.value = token;
};

const verifyInformation = handleSubmit((values) => {
  authStore
    .validateChallenge({
      document_type: authStore.userChallenge.document_type,
      document_number: authStore.userChallenge.document_number,
      org_id: authStore.organizationClientId,
      answer_1: values.name,
      answer_2: values.address,
    })
    .catch((error) => {
      const { detail } = error.data;
      errorMessage.value = detail;

      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    });
});

const clientTheme = clientStore.theme;
</script>

<template>
  <div class="flex flex-grow" :class="clientTheme">
    <div class="container mx-5 md:mx-auto pt-2 md:pt-8 md:w-3/4 h-[100%]">
      <h1 class="text-lg md:text-[25px] my-4 mb-6 md:mb-[42px] title">
        ¡Hola!
      </h1>

      <h2 class="text-[15px] md:text-lg 2xl:text-[20px] title mb-3">
        Validá tus datos
      </h2>
      <form
        class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-3 gap-3 md:gap-2 xl:gap-4 md:border-section"
        @submit.prevent="verifyInformation"
      >
        <div
          class="md:px-[36px] py-[37px] px-5 md:pr-0 xl:pr-4 border-section md:border-0"
        >
          <span class="title text-[13px] md:text-base 2xl:text-lg"
            >Nombre y Apellido</span
          >
          <div class="mt-3 flex flex-col gap-4 md:gap-3 2xl:gap-4">
            <BaseRadioGroup
              v-model="name"
              name="name"
              :options="authStore.answers[0].question_1"
              vertical
              :error="errors.name"
            />
          </div>
        </div>

        <div
          class="p-4 py-[37px] px-4 md:px-0 xl:px-4 border-section md:border-0"
        >
          <span class="title text-[13px] md:text-base 2xl:text-lg"
            >Calle y Altura</span
          >
          <div class="mt-3 flex flex-col flex-nowrap gap-4 md:gap-3 2xl:gap-4">
            <BaseRadioGroup
              v-model="address"
              name="address"
              :options="authStore.answers[0].question_2"
              vertical
              :error="errors.address"
            />
          </div>
        </div>

        <div
          class="flex flex-col xl:col-span-2 2xl:col-span-1 justify-center items-center mr-0 md:mr-10 xl:mr-0"
        >
          <span
            v-if="!recaptcha"
            class="text-[#D23232] font-medium text-[15px] 2xl:text-base font-montserrat"
            >{{ errors.recaptcha }}</span
          >
          <ReCAPTCHA @verify="callbackVerify" />

          <button
            type="submit"
            class="w-[230px] lg:w-[275px] mt-3 text-base md:text-sm btn btn-salmon py-[14px] md:py-3 bg-skin-major hover:bg-skin-button-bg-hover"
          >
            Continuar
          </button>

          <span v-if="errorMessage" class="text-[#D23232]">{{
            errorMessage
          }}</span>
        </div>
      </form>
    </div>
  </div>
</template>
