import { defineStore } from "pinia";
import { fetchForm } from "@/services/request.services";
import { useProductStore } from "./productStore";

interface Request {
  id: number;
  nro_poliza: number;
  options: Array<any>;
}

export const useRequestStore = defineStore("request", {
  state: () => ({
    currentRequest: {} as Request,
    formType: "",
    emergencyService: false,
    finalMessage: "",
  }),

  getters: {
    initialValues(): Record<string, any> {
      const store = useProductStore();
      return {
        id_poliza: store.currentPolicy.id ?? undefined,
        cellphone_number: store.insuredInfo.cellphone_number,
        backup_cellphone_number: store.insuredInfo.telefono_respaldo,
        email: store.insuredInfo.email,
        id_servicio: "",
        scheme_id: "",
        tipo_formulario: "",
        holder_person:
          store.insuredInfo.name + " " + store.insuredInfo.last_name,
        cuil: store.insuredInfo.cuil ?? undefined,
        cbu: store.insuredInfo.cbu,
        imagenes: [],
        street_name: store.insuredInfo.street_name,
        street_number: store.insuredInfo.street_number,
        floor: store.insuredInfo.floor,
        department: store.insuredInfo.department,
        province: store.insuredInfo.province?.name,
        city: store.insuredInfo.city?.name,
        is_urgency: false,
        availability: [{ date: null, range: "" }],
        comment: "",
        claim_date: null,
        equipos: [{ detalle_reclamo: "", valor_estimado: null, imagenes: [] }],
      };
    },
  },

  actions: {
    async requestPreload(id: number): Promise<any> {
      try {
        const { data } = await fetchForm(id);
        this.currentRequest = data;
      } catch (error) {
        console.log(error);
      }
    },

    setFormType(type: string): void {
      this.formType = type;
    },

    setFinalMessage(mensaje: string | any): void {
      this.finalMessage = mensaje;
    },
  },
});
