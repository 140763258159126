export enum Forms {
  Refund = "REINTEGRO",
  Benefit = "BENEFICIO",
  Assistance = "SINIESTRO",
}

export enum BenefitOption {
  Technician = "Solicitar un profesional",
  Refund = "Tengo mi profesional de confianza",
}
