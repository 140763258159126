<script lang="ts">
export default {
  name: "CommerceIcon",
};
</script>

<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="fill-current"
    x="0px"
    y="0px"
    viewBox="0 0 139.8 139.8"
    style="enable-background: new 0 0 139.8 139.8"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M136.6,118.2c-0.7-0.9-1.8-1.5-3.1-1.5c-1.2,0-2.3,0-3.5,0s-2.3,0-3.5,0c-1.1,0-2.2,0-3.4,0h-0.5l-0.1-0.9
			c0-0.3-0.1-0.5-0.1-0.7v-5.6c0-13.4,0-27.3,0.1-41c0-0.8,0.7-1.8,1.4-2.2c4.2-2.7,6.9-6.3,8.1-10.7c0.6-2.3,1.2-4.5,0.3-6.8
			l-3.9-10.7c-2-5.5-4-11-6-16.5c-0.8-2.2-1.8-2.9-4.2-2.9c-32.2,0-64.4,0-96.6,0c-2.5,0-3.5,0.7-4.3,3c-3.2,8.8-6.5,17.8-9.8,26.9
			c-0.3,1-0.5,1.9-0.5,2.7c0.4,6.5,3.3,11.5,8.7,14.9c1.1,0.7,1.6,1.5,1.6,2.9c0,12.6,0,25.2,0,37.8l0,9.9H7.6
			c-1.9,0-3.3,0.5-4.2,1.3c-0.6,0.6-0.9,1.4-0.9,2.4c0.1,3,2.8,3.6,5,3.6h125.1c0.4,0,0.9,0,1.3,0c1.5-0.1,2.8-1.1,3.2-2.6
			C137.4,120.2,137.2,119.1,136.6,118.2z M73.6,34c0-2.5,0-5,0-7.5v-0.5h17.7l1.5,8c0.9,5,1.8,10,2.8,14.9c0.8,4.1-0.6,7.6-4.1,10.3
			c-2,1.6-4.2,2.4-6.6,2.4c-1.5,0-3.1-0.3-4.7-1c-4.1-1.8-6.4-5-6.6-9C73.5,45.8,73.5,39.8,73.6,34z M44.3,49.4
			c0.8-5.2,1.8-10.5,2.8-15.6c0.4-2,0.7-3.9,1.1-5.9c0.1-0.3,0.2-0.6,0.2-0.9l0.2-0.8H66l0.2,0.5c0,0.2,0.1,0.4,0.1,0.6l0,5.6
			c0,5.9,0,12,0,18.1c-0.1,5.1-3.9,9.7-8.9,10.6c-0.7,0.1-1.5,0.2-2.2,0.2c-4.6,0-8.7-2.7-10.3-6.9C44.2,53.1,44,51.1,44.3,49.4z
			 M14.6,53.1c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.7,0.1-1.4,0.3-2c1.5-4.3,3.1-8.6,4.6-12.8c1.1-3.1,2.3-6.2,3.4-9.4
			c0.5-1.2,1.2-1.7,2.4-1.7c3.6,0.1,7.2,0,11,0l4.7,0l-0.7,3.8c-0.8,4.2-1.5,8.3-2.2,12.4c-0.5,3.1-1.1,6.5-1.5,9.8
			c-0.7,5.3-5.1,9.4-10.5,9.7c-0.2,0-0.4,0-0.7,0C20.3,61.7,15.7,58,14.6,53.1z M85.5,116.7l-0.5,0.1H54.3V91.3
			c0-2.2,1.8-3.9,3.9-3.9h23.4c2.2,0,3.9,1.8,3.9,3.9V116.7z M115.1,116.7H92.7V91.3c0-6.1-5-11.1-11.1-11.1H58.2
			c-6.1,0-11.1,5-11.1,11.1v25.3l-22.4,0V69.1l0.5,0c6-0.1,10.9-2.5,14.7-6.9l0.4-0.5l0.4,0.4c3.8,4.4,8.9,6.8,14.4,6.9
			c5.5,0,10.7-2.4,14.5-6.8l0.4-0.4l0.4,0.4c3.9,4.5,8.8,6.8,14.3,6.8h0c5.7,0,10.5-2.3,14.4-6.9l0.4-0.4l0.4,0.4
			c3.8,4.5,8.8,6.8,14.7,7l0.5,0V116.7z M114.3,61.7c-0.4,0-0.8,0-1.2-0.1c-5.2-0.5-9.3-4.8-10-10.4c-0.5-3.9-1.2-7.9-1.9-11.8
			c-0.3-1.6-0.6-3.2-0.9-4.8c-0.3-1.8-0.6-3.5-1-5.4L98.8,26h3.8c4.3,0,8.4,0,12.5,0c0.6,0,1.4,0.7,1.6,1.2
			c2.6,6.9,5.6,15.1,8.5,23.1c0.3,0.8,0.2,1.8-0.1,3C123.8,58.3,119.4,61.7,114.3,61.7z"
        />
        <path
          class="st0"
          d="M85,116.7h0.5v-0.1L85,116.7z M85,116.7h0.5v-0.1L85,116.7z M85,116.7h0.5v-0.1L85,116.7z M85,116.7h0.5v-0.1
			L85,116.7z M85,116.7h0.5v-0.1L85,116.7z"
        />
      </g>
    </g>
  </svg>
</template>
