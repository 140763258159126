<script setup lang="ts">
import TextInput from "./TextInput.vue";
import TextareaInput from "./TextareaInput.vue";
import VeeDate from "./VeeDate.vue";
import { computed } from "vue";

const today = computed(() => {
  return new Date().toISOString().split("T")[0];
});
</script>

<template>
  <h1 class="title my-3 text-base md:text-lg 2xl:text-xl">¿Dónde y cuándo?</h1>
  <div
    class="grid grid-cols-1 md:grid-cols-2 border-section py-4 2xl:py-8 px-4 md:px-8 2xl:px-12 gap-x-9"
  >
    <div class="flex flex-col gap-4">
      <TextInput
        label="Provincia"
        name="province"
        type="text"
        class="title text-xs md:text-sm 2xl:text-base font-medium"
        read-only
      />

      <!-- Mobile -->
      <TextInput
        label="Localidad"
        name="city"
        type="text"
        class="md:hidden title text-xs font-medium"
        read-only
      />
      <!-- Mobile -->

      <TextInput
        name="street_name"
        type="text"
        label="Calle"
        class="title text-xs md:text-sm 2xl:text-base font-medium"
      />

      <!-- Mobile -->
      <TextInput
        name="street_number"
        label="Número"
        type="text"
        class="title md:hidden text-xs font-medium"
      />
      <div class="md:hidden flex gap-x-2">
        <TextInput
          name="floor"
          label="Piso"
          hint="(opcional)"
          type="text"
          class="md:hidden title text-xs font-medium"
        />

        <TextInput
          name="department"
          label="Depto"
          hint="(opcional)"
          type="text"
          class="md:hidden title text-xs font-medium"
        />
      </div>
      <!-- Mobile -->

      <VeeDate
        name="claim_date"
        label="Fecha de incidente"
        class="text-grayDetails-400 text-xs md:text-sm 2xl:text-base"
        :max="today"
      />
    </div>

    <div class="flex flex-col gap-4">
      <TextInput
        label="Localidad"
        name="city"
        type="text"
        class="hidden md:block title md:text-sm 2xl:text-base font-medium"
        read-only
      />

      <div class="flex gap-4">
        <TextInput
          name="street_number"
          label="Número"
          type="text"
          class="title hidden md:block md:text-sm 2xl:text-base font-medium"
        />

        <TextInput
          name="floor"
          label="Piso"
          hint="(opcional)"
          type="text"
          class="hidden md:block title md:text-sm 2xl:text-base font-medium"
        />

        <TextInput
          name="department"
          label="Depto"
          hint="(opcional)"
          type="text"
          class="hidden md:block title md:text-sm 2xl:text-base font-medium"
        />
      </div>

      <div class="flex flex-col">
        <TextareaInput
          name="comment"
          label="Contanos qué sucedió"
          class="text-xs md:text-base"
          placeholder="Ejemplo: Se derramó agua sobre la notebook y ya no prende más la pantalla, pero las teclas si."
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
