<script setup lang="ts">
import { useClientStore } from "@/stores/clientStore";

import PhoneIcon from "@/components/icons/PhoneIcon.vue";
import WhatsappIcon from "@/components/icons/WhatsappIcon.vue";

const clientStore = useClientStore();
const phoneCall = clientStore.formatPhoneNumber;
const whatsappCall = clientStore.formatWhatsappNumber;
</script>

<template>
  <div class="md:hidden flex text-center flex-col">
    <p class="title text-sm">¿Necesitás ayuda?</p>
    <p class="text-primary text-sm text-grayDetails-400">
      Comunicate con nosotros
    </p>
  </div>
  <div class="md:hidden flex justify-center mt-3 gap-x-8">
    <button
      type="button"
      class="border-2 border-skin-major rounded-full text-skin-highlight group"
    >
      <span class="sr-only">View notifications</span>
      <a :href="phoneCall" rel="noopener noreferrer" target="_blank">
        <PhoneIcon
          class="mx-3 text-skin-major rounded-full h-[14px] w-[14]"
          aria-hidden="true"
        />
      </a>
    </button>

    <button
      type="button"
      class="border-2 border-skin-major rounded-full text-skin-highlight group"
    >
      <a :href="whatsappCall" rel="noopener noreferrer" target="_blank">
        <WhatsappIcon
          class="text-skin-major m-[10px] h-5 w-5"
          aria-hidden="true"
        />
      </a>
    </button>
  </div>
</template>
