import { object, number, string, array, date, boolean } from "yup";

export const schema_1 = object().shape({
  id_poliza: number().required("Seleccioná una póliza *"),
  cellphone_number: string().required("Ingresá tu teléfono *").nullable(),
  backup_cellphone_number: string().nullable(),
  email: string()
    .required("Ingresá tu mail *")
    .nullable()
    .email("Debe ser una email válido"),
});

export const schema_2 = object().shape({
  id_servicio: string().required("Seleccioná un servicio *").nullable(),
  scheme_id: string().required("Seleccioná un esquema *").nullable(),
  tipo_formulario: string()
    .required("Seleccioná un tipo de reparación *")
    .nullable(),
  holder_person: string(),
  cuil: string()
    .required("Ingresá el CUIL de la cuenta *")
    .matches(
      /^(?:[0-9]{2}-[0-9]{8}-[0-9]{1}|)$/,
      "Ingresá el CUIL con el formato correcto"
    )
    .nullable(true),
  cbu: string()
    .required("Debe ingresar un cbu *")
    .matches(/^(?:[0-9]+|)$/, "Debe ingresar solo números")
    .min(22, "Ingresá los 22 dígitos del CBU")
    .max(22, "Ingresá los 22 dígitos del CBU")
    .nullable(true),
  imagenes: array()
    .of(number().required())
    .min(1, "Subí al menos una imagen *"),
  street_name: string().required("Ingresá tu calle *").nullable(),
  street_number: string().required("Ingresá tu número *").nullable(),
  floor: string().nullable(),
  department: string().nullable(),
  province: string().nullable(),
  city: string().nullable(),
  is_urgency: boolean(),
  availability: array()
    .of(
      object().shape({
        date: string()
          .nullable()
          .required("Ingresá al menos una fecha disponible *"),
        range: date().required("Ingresá la franja horaria disponible *"),
      })
    )
    .min(1, "Debe ingresar al menos una franja horaria"),
  comment: string().required("Comentá lo sucedido *"),
  claim_date: string().required("Seleccioná una fecha *").nullable(),
  claim_description: string().required("Seleccioná una opción *"),
});

export const step2Refund = object().shape({
  id_servicio: string().required("Seleccioná un servicio *").nullable(),
  scheme_id: string().required("Seleccioná un esquema *").nullable(),
  tipo_formulario: string()
    .required("Seleccioná un tipo de reparación *")
    .nullable(),
  holder_person: string(),
  cuil: string()
    .required("Ingresá el CUIL de la cuenta *")
    .matches(
      /^(?:[0-9]{2}-[0-9]{8}-[0-9]{1}|)$/,
      "Ingresá el CUIL con el formato correcto"
    )
    .nullable(true),
  cbu: string()
    .required("Debe ingresar un cbu")
    .matches(/^(?:[0-9]+|)$/, "Debe ingresar solo números")
    .min(22, "Ingresá los 22 dígitos del CBU")
    .max(22, "Ingresá los 22 dígitos del CBU")
    .nullable(true),
  comment: string().required("Comentá lo sucedido *"),
  imagenes: array()
    .of(number().required())
    .min(1, "Subí al menos una imagen *"),
});

export const step2Benefit = object().shape({
  id_servicio: string().required("Seleccioná un servicio *").nullable(),
  scheme_id: string().required("Seleccioná un esquema *").nullable(),
  tipo_formulario: string()
    .required("Seleccioná un tipo de reparación *")
    .nullable(),
  street_name: string().required("Ingresá tu calle *").nullable(),
  street_number: string().required("Ingresá el número *").nullable(),
  floor: string().nullable(),
  department: string().nullable(),
  province: string().nullable(),
  city: string().nullable(),
  is_urgency: boolean(),
  availability: array()
    .of(
      object().shape({
        date: string()
          .nullable()
          .required("Ingresá al menos una fecha disponible *"),
        range: string().required("Ingresá la franja horaria disponible *"),
      })
    )
    .strict(),
  comment: string().required("Comentá lo sucedido *"),
});

export const step2Assistance = object().shape({
  scheme_id: string().required("Seleccioná un esquema *").nullable(),
  tipo_formulario: string()
    .required("Seleccioná un tipo de reparación *")
    .nullable(),
  street_name: string().required("Ingresá tu calle *").nullable(),
  street_number: string().required("Ingresá el número *").nullable(),
  floor: string().nullable(),
  department: string().nullable(),
  province: string().nullable(),
  city: string().nullable(),
  comment: string().required("Comentá lo sucedido *"),
  claim_date: string().required("Seleccioná una fecha *").nullable(),
  claim_description: string().required("Seleccioná una opción *"),
});

export const schema_3 = object().shape({
  imagenes: array()
    .of(number().required())
    .min(1, "Subí al menos una imagen *"),
  equipos: array()
    .of(
      object().shape({
        valor_estimado: string()
          .matches(
            /^(?:[-+]?[0-9]+[.]?[0-9]*([eE][-+]?[0-9]+)?|)$/,
            "Ingresá solo números"
          )
          .nullable(),
        detalle_reclamo: string().required("Detallá el reclamo *"),
        imagenes: array()
          .of(number().required())
          .min(1, "Subí al menos una imagen *"),
      })
    )
    .strict(),
});

export const step3Benefit = object().shape({
  imagenes: array()
    .of(number().required())
    .min(1, "Subí al menos una imagen *"),
});

export const step3Assistance = object().shape({
  equipos: array()
    .of(
      object().shape({
        valor_estimado: string()
          .matches(
            /^(?:[-+]?[0-9]+[.]?[0-9]*([eE][-+]?[0-9]+)?|)$/,
            "Debe ingresar solo números"
          )
          .nullable(),
        detalle_reclamo: string().required("Detallá el reclamo *"),
        imagenes: array()
          .of(number().required())
          .min(1, "Subí al menos una imagen *"),
      })
    )
    .strict(),
});
