<script setup lang="ts">
import { XIcon } from "@heroicons/vue/solid";
import { useClientStore } from "@/stores/clientStore";
import { watch } from "vue";

const clientStore = useClientStore();

defineEmits<{
  (e: "closeModal"): void;
}>();

const props = defineProps<{
  closeIcon?: boolean;
  isOpen: boolean;
}>();

const clientTheme = clientStore.theme;

watch(
  () => props.isOpen,
  (newValue) => {
    if (newValue) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  },
  {
    immediate: true,
  }
);
</script>
<template>
  <Transition name="modal">
    <div
      v-if="isOpen"
      class="modal-mask fixed flex justify-center items-center z-50 inset-0"
      :class="clientTheme"
    >
      <div class="align-middle p-5">
        <div
          class="modal-container bg-white rounded-md m-auto p-5 pt-1 md:px-10 md:py-7"
        >
          <div class="grid">
            <XIcon
              class="cursor-pointer w-6 md:w-7 top-1 p-1 ml-auto text-grayDetails-200 md:-right-5 rounded-full transition-all hover:bg-grayDetails-300 duration-300"
              v-if="closeIcon"
              @click="$emit('closeModal')"
              type="button"
            />
            <slot name="header"></slot>
          </div>
          <div class="my-5 mx-0">
            <slot name="body"></slot>
          </div>
          <div class="flex justify-center items-center">
            <slot name="footer">
              <div v-if="!closeIcon" class="flex flex-col items-center">
                <button
                  @click="$emit('closeModal')"
                  class="btn btn-transition btn-salmon w-40 md:w-48 2xl:w-56 py-3 bg-skin-major hover:bg-skin-button-bg-hover"
                >
                  Cerrar
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.x-icon {
  justify-self: end !important;
}
.modal-mask {
  display: flex !important;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
