import { initializeApp, getApps } from "firebase/app";
import { getMessaging } from "firebase/messaging";

export const firebaseConfig = {
  apiKey: "AIzaSyCoWoENixUVHz43t4BfL0DK6fiMrmBKDs4",
  authDomain: "rapihogar-1496755516806.firebaseapp.com",
  databaseURL: "https://rapihogar-1496755516806.firebaseio.com",
  projectId: "rapihogar-1496755516806",
  storageBucket: "rapihogar-1496755516806.appspot.com",
  messagingSenderId: "118480623465",
  appId: "1:118480623465:web:73a80737925ff1e5458ff8",
  measurementId: "G-7F2VPCZRCG",
};

const apps = getApps();
const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0];
const messaging = getMessaging(firebaseApp);

export { messaging };
