import { axiosInstance as axios } from "./axios.config";

import type {
  Challenge,
  User,
  UserChallenge,
  UserInformation,
} from "@/interfaces/Auth";
import type { ItemOption } from "@/interfaces/Inputs";

export const login = async (
  challenge: Challenge
): Promise<{ data: UserChallenge }> => {
  return await axios.post("/challenge", challenge);
};

export const validateLogin = async (
  user: UserInformation
): Promise<{ data: User }> => {
  return await axios.post("/validate_challenge", user);
};

export const getDocumentsType = async (): Promise<{ data: ItemOption[] }> => {
  return await axios.get("/tipo_documento/");
};

interface AutologinBody {
  organization_id: number | string;
  key: unknown;
}

export const autologin = async (
  data: AutologinBody
): Promise<{ data: User }> => {
  return await axios.post("/autologin", data);
};
