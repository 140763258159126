<script setup lang="ts">
import { RouterLink } from "vue-router";
import type { Notification } from "@/stores/notifications";
import { useClientStore } from "@/stores/clientStore";

import AppNotification from "./AppNotification.vue";

defineProps<{
  notifications?: Notification[];
  showDropdown: boolean;
}>();

const clientStore = useClientStore();
const clientTheme = clientStore.theme;
</script>

<template>
  <div
    v-if="showDropdown"
    :class="clientTheme"
    class="text-grayDetails-400 border cursor-auto border-grayDetails-100 bg-white rounded-md shadow-md px-2 pt-4 pb-2 w-[282px]"
  >
    <div class="flex justify-between items-center mb-3">
      <h1 class="text-left font-bold font-montserrat text-base ml-2">
        Notificaciones
      </h1>
      <RouterLink
        class="text-skin-major font-medium font-montserrat text-xs mr-2 px-2 py-1 rounded-lg hover:bg-skin-major-light transition duration-200"
        :to="{ name: 'Notifications' }"
      >
        Ver todas
      </RouterLink>
    </div>
    <ul class="text-base">
      <li
        v-for="(notification, index) of notifications"
        :key="index"
        :class="[notification.read ? '' : 'bg-blueNav-100 bg-opacity-5']"
        class="flex flex-col text-left mb-2 rounded-md hover:bg-grayDetails-300 hover:rounded-md last:border-0"
      >
        <AppNotification :notification="notification" />
      </li>
    </ul>
  </div>
</template>

<style scoped>
li:nth-of-type(n + 6) {
  display: none;
}
</style>
