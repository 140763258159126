<script lang="ts">
export default {
  name: "MobilityIcon",
};
</script>

<template>
  <svg
    width="96"
    height="66"
    viewBox="0 0 96 66"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.1138 28.83C79.0162 27.1363 75.22 27.1363 71.7853 27.9831L64.0121 7.53714L73.4123 4.69406C74.6777 4.33111 75.4008 3.0003 74.979 1.72999C74.6174 0.459678 73.2918 -0.266216 72.0264 0.157222L60.1556 3.7262C59.553 3.90767 59.0107 4.3916 58.7094 4.93602C58.4081 5.54093 58.4081 6.20633 58.6491 6.81124L60.9389 12.7999L41.6565 23.0229L37.2577 10.7432H39.4872C40.8129 10.7432 41.8372 9.65433 41.8372 8.38401C41.8372 7.1137 40.7526 6.02486 39.4872 6.02486H28.8216C27.4959 6.02486 26.4715 7.1137 26.4715 8.38401C26.4715 9.65433 27.5562 10.7432 28.8216 10.7432H32.3768C32.3768 10.9851 32.437 11.2876 32.4973 11.5296L44.0668 44.0738H38.1013C36.8961 34.3347 28.3998 26.8943 18.2162 27.4387C8.57499 27.9226 0.56071 36.1494 0.319679 45.8885C0.0183906 56.6559 8.63524 65.4876 19.3009 65.4876C29.0023 65.4876 36.9564 58.2287 38.1615 48.7921H47.8028C48.7066 48.7921 49.5502 48.2477 49.972 47.4613L63.4095 19.3934L67.3262 29.6769C60.5774 33.3064 56.2388 40.9282 57.5042 49.4575C58.7094 57.6843 65.4583 64.3383 73.7136 65.4271C85.3433 66.9394 95.2858 57.9262 95.2858 46.5539C95.2858 38.7505 90.2844 31.7941 83.1138 28.83ZM19.3009 60.7693C11.4071 60.7693 5.08004 54.3573 5.08004 46.4934C5.08004 38.6296 11.4674 32.2175 19.3009 32.2175C26.351 32.2175 32.196 37.3593 33.3409 44.1343H19.3009C17.9752 44.1343 16.9508 45.2231 16.9508 46.4934C16.9508 47.7637 18.0354 48.8526 19.3009 48.8526H33.3409C32.196 55.6276 26.351 60.7693 19.3009 60.7693ZM47.9233 40.6863L43.2232 27.4992L57.9863 19.7563L47.9233 40.6863ZM76.3046 60.7693C68.4109 60.7693 62.0838 54.3573 62.0838 46.4934C62.0838 41.2307 64.9159 36.6939 69.0737 34.2137L74.0751 47.4008C74.5572 48.6106 75.9431 49.276 77.1483 48.7921C78.3534 48.3081 79.0163 46.9169 78.5342 45.707L73.5328 32.52C74.4367 32.3385 75.4008 32.2175 76.3046 32.2175C84.1984 32.2175 90.5255 38.6296 90.5255 46.4934C90.5255 54.3573 84.1381 60.7693 76.3046 60.7693Z"
      fill="currentColor"
    />
  </svg>
</template>
