<script lang="ts">
export default {
  name: "RequestIcon",
};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path
      d="M5.3,22.4c-0.4-0.1-0.8-0.3-0.9-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-4.7,0-9.4,0-14.1c0-0.4,0-0.8,0-1.3c0-0.8,0.6-1.4,1.4-1.4
	c0.8,0,1.7,0,2.5,0c0-0.2,0-0.4,0-0.6c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.8,0,1.2,0c0.1,0,0.2,0,0.2-0.2C10.6,2,11.2,1.6,12,1.6
	c0.8,0,1.4,0.4,1.8,1.1c0.1,0.1,0.1,0.2,0.2,0.2c0.4,0,0.8,0,1.2,0c0.4,0,0.7,0.3,0.7,0.7c0,0.2,0,0.4,0,0.6c0.1,0,0.2,0,0.2,0
	c0.7,0,1.5,0,2.2,0c0.9,0,1.4,0.5,1.4,1.4c0,5,0,10.1,0,15.1c0,1-0.2,1.3-1.1,1.6C14.2,22.4,9.8,22.4,5.3,22.4z M18.5,21.1
	c0-5.2,0-10.4,0-15.5c-0.9,0-1.7,0-2.6,0c0,0.2,0,0.4,0,0.6c0,0.5-0.3,0.7-0.7,0.7c-2.1,0-4.2,0-6.3,0c-0.5,0-0.7-0.3-0.7-0.7
	c0-0.2,0-0.4,0-0.6c-0.9,0-1.7,0-2.6,0c0,5.2,0,10.3,0,15.5C9.8,21.1,14.1,21.1,18.5,21.1z M9.4,4.2c0,0.4,0,0.9,0,1.3
	c1.7,0,3.4,0,5.2,0c0-0.4,0-0.8,0-1.3c0,0,0,0,0,0c-0.4,0-0.8,0-1.2,0c-0.4,0-0.7-0.2-0.7-0.7c0-0.4-0.3-0.6-0.7-0.6
	c-0.3,0-0.6,0.3-0.6,0.6c0,0.4-0.3,0.7-0.7,0.7C10.2,4.2,9.8,4.2,9.4,4.2z M16.5,12c0.4,0,0.7-0.3,0.7-0.6c0-0.4-0.3-0.7-0.7-0.7
	c-0.8,0-6,0-6.8,0C9.2,10.7,9,11,9,11.4C9,11.7,9.3,12,9.7,12c0.4,0,5.2,0,5.6,0C15.6,12,16.1,12,16.5,12z M8.2,11.4L8.2,11.4
	c0-0.4-0.3-0.7-0.7-0.7h0c-0.4,0-0.7,0.3-0.7,0.7v0c0,0.4,0.3,0.7,0.7,0.7h0C7.9,12,8.2,11.7,8.2,11.4z M16.5,14.5
	c0.4,0,0.7-0.3,0.7-0.6c0-0.4-0.3-0.7-0.7-0.7c-0.8,0-6,0-6.8,0c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.6,0.7,0.6c0.4,0,5.2,0,5.6,0
	C15.6,14.5,16.1,14.5,16.5,14.5z M8.2,13.9L8.2,13.9c0-0.4-0.3-0.7-0.7-0.7h0c-0.4,0-0.7,0.3-0.7,0.7v0c0,0.4,0.3,0.7,0.7,0.7h0
	C7.9,14.5,8.2,14.2,8.2,13.9z M16.5,17c0.4,0,0.7-0.3,0.7-0.6c0-0.4-0.3-0.7-0.7-0.7c-0.8,0-6,0-6.8,0C9.2,15.7,9,16,9,16.4
	C9,16.7,9.3,17,9.7,17c0.4,0,5.2,0,5.6,0S16.1,17,16.5,17z M8.2,16.4L8.2,16.4c0-0.4-0.3-0.7-0.7-0.7h0c-0.4,0-0.7,0.3-0.7,0.7v0
	c0,0.4,0.3,0.7,0.7,0.7h0C7.9,17,8.2,16.7,8.2,16.4z"
    />
  </svg>
</template>
