import { useForm } from "vee-validate";
import { array, boolean, number, object, string } from "yup";
import { useRefundStore } from "../stores/refundStore";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { submitRefund } from "../services/refund.services";

export const useRefundForm = () => {
  const refundStore = useRefundStore();
  const submissionSuccess = ref(false);
  const { clientDefaultValues } = storeToRefs(refundStore);
  const { getCookie, removeCookie } = refundStore;

  const schema = object({
    uploadDocumentation: boolean(),
    nombre_cliente: string(),
    cbu_cliente: string()
      .required("Completá este dato.")
      .matches(/^(?:[0-9]+|)$/, "Debe ingresar solo números")
      .min(22, "Ingresá los 22 dígitos del CBU")
      .max(22, "Ingresá los 22 dígitos del CBU")
      .nullable(true),
    cuil_cliente: string()
      .required("Completá este dato.")
      .matches(
        /^(?:[0-9]{2}-[0-9]{8}-[0-9]{1}|)$/,
        "El CUIL no tiene un formato válido."
      )
      .nullable(true),
    email_cliente: string()
      .required("Completá este dato.")
      .email("El email no tiene un formato válido."),
    cuit_prestador: string()
      .required("Completá este dato.")
      .matches(
        /^(?:[0-9]{2}-[0-9]{8}-[0-9]{1}|)$/,
        "El CUIT no tiene un formato válido."
      ),
    nombre_prestador: string().required("Completá este dato."),
    total_servicio: string()
      .required("Completá este dato.")
      .matches(
        /^\$?\d+(,\d{2})?$/,
        "Ingresá solo números con hasta dos decimales."
      )
      .nullable(true),
    imagen_factura: array()
      .of(number())
      .min(1, "Por favor, subí al menos un archivo."),
    fotos_antes: array().when("uploadDocumentation", {
      is: true,
      then: (schema) =>
        schema.of(number()).min(1, "Por favor, subí al menos un archivo."),
      otherwise: (schema) => schema.nullable(true),
    }),
    fotos_despues: array().when("uploadDocumentation", {
      is: true,
      then: (schema) =>
        schema.of(number()).min(1, "Por favor, subí al menos un archivo."),
      otherwise: (schema) => schema.nullable(true),
    }),
  });

  const { setFieldValue, handleSubmit, errors } = useForm({
    validationSchema: schema,
    initialValues: {
      uploadDocumentation: false,
      nombre_cliente: "",
      cbu_cliente: "",
      cuil_cliente: "",
      email_cliente: "",
      cuit_prestador: "",
      nombre_prestador: "",
      total_servicio: null,
      imagen_factura: [],
      fotos_antes: [],
      fotos_despues: [],
    },
  });

  watch(
    clientDefaultValues,
    (client) => {
      if (client) {
        setFieldValue("cbu_cliente", client.cbu);
        setFieldValue("cuil_cliente", client.cuil);
        setFieldValue("nombre_cliente", client.fullName);
        setFieldValue("email_cliente", client.email);
        setFieldValue("uploadDocumentation", client.mustUploadDocumentation);
      }
    },
    { immediate: true, deep: true }
  );

  const submitForm = handleSubmit(
    async (values) => {
      try {
        const key = getCookie("refund-key");
        await submitRefund(values, key);
        submissionSuccess.value = true;
        removeCookie("refund-key");
      } catch (error) {
        console.log(error);
        submissionSuccess.value = false;
      }
    },
    ({ errors }) => {
      const fieldName = Object.keys(errors)[0];
      const el = document.querySelector(`[name=${fieldName}]`);
      el?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  );

  return { submitForm, errors, submissionSuccess };
};
