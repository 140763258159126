<template>
  <div class="flex flex-col w-full border-none p-0">
    <div class="flex items-center gap-1">
      <label
        :for="name"
        class="title text-grayDetails-400 text-sm 2xl:text-base"
        >{{ label }}</label
      >
      <span class="font-medium" v-show="hint">{{ hint }}</span>
    </div>

    <input
      v-bind="$attrs"
      :disabled="readOnly"
      class="base-input w-full text-sm 2xl:text-base"
      :class="{ 'cursor-not-allowed': readOnly }"
      :placeholder="placeholder"
      :name="name"
      :id="id ? id : name"
      :type="type"
      :value="inputValue"
      @input="handleChange"
      @blur="handleBlur"
    />

    <BaseErrorMessage
      :id="id ? id : name"
      class="font-medium font-montserrat text-xs md:text-sm 2xl:text-base text-[#D23232]"
      v-show="errorMessage && meta.touched"
    >
      {{ errorMessage }}
    </BaseErrorMessage>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from "vue";
import { useField } from "vee-validate";

const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  value: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  hint: {
    type: String,
    default: "",
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const name = toRef(props, "name");

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>
