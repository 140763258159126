<script setup lang="ts">
import { ref, toRef, watchEffect } from "vue";
import _ from "lodash";
import { XIcon } from "@heroicons/vue/solid";

import { uploadImage, deleteImage } from "@/services/request.services";
import { useField } from "vee-validate";

const props = withDefaults(
  defineProps<{
    labelButton?: string;
    formType?: string;
    name: string;
  }>(),
  {
    labelButton:
      "Hacé click y subí las fotos de los objetos a reparar o instalar. Recordá tomar las fotos con buena iluminación.",
    formType: "",
  }
);

const name = toRef(props, "name");
const files = ref<any>([]);

const { value: imagesId, errorMessage, meta } = useField<Array<number>>(name);

const progressBarValue = ref(0);
const imageUploader = ref<HTMLInputElement | null>(null);
const uploadedImages = ref<any>([]);
const isUploading = ref(false);

watchEffect(() => {
  if (props.formType === "") {
    uploadedImages.value = [];
    imagesId.value = [];
  }
});

async function handleChange() {
  if (Number(imageUploader?.value?.files?.length) > 1) {
    alert("Cargar una imagen a la vez");
    return;
  }

  files.value = imageUploader.value?.files;

  if (files.value?.length == 0) return;

  await uploadImages();
}

const uploadImages = async (): Promise<void> => {
  const formData = new FormData();
  _.forEach(files.value, (file) => {
    formData.append("file", file);
    formData.append("file_name", file.name);
    formData.append("tipo_formulario", props.formType);
  });

  isUploading.value = true;

  const { data } = await uploadImage(formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (e) =>
      (progressBarValue.value = Math.round((e.loaded * 100) / e.total)),
  });

  uploadedImages.value = [...uploadedImages.value, data];
  imagesId.value = uploadedImages.value.map((img) => img.id);
  files.value = [];
  isUploading.value = false;
  imageUploader.value = null;
};

async function removeImage(id: number) {
  await deleteImage(id);
  uploadedImages.value = uploadedImages.value.filter(
    (image) => image.id !== id
  );
  imagesId.value = uploadedImages.value.map((img) => img.id);
}

const handleClick = () => {
  imageUploader.value?.click();
};

function isImage(file: any) {
  const ext = file.substring(file.lastIndexOf("."), file.length);
  if (ext !== ".pdf") return true;
  return false;
}

function isPdf(file: any) {
  const ext = file.substring(file.lastIndexOf("."), file.length);
  if (ext == ".pdf") return true;
  return false;
}
</script>

<template>
  <div class="wrapper">
    <form class="mb-4 md:mb-6 2xl:mb-8 cursor-pointer" @click="handleClick">
      <img class="w-11 md:w-14" src="@/assets/nube.svg" alt="uploadCloud" />

      <input
        accept="image/*, application/pdf"
        class="hidden"
        type="file"
        ref="imageUploader"
        @change="handleChange"
        multiple
      />
      <button
        class="text-grayDetails-200 py-2 px-4 md:px-3 font-medium text-[10px] md:text-base font-montserrat"
        type="button"
      >
        {{ props.labelButton }}
      </button>
    </form>

    <p
      class="text-[#D23232] text-xs md:text-sm 2xl:text-base font-medium font-montserrat"
      v-if="errorMessage && meta.touched"
    >
      {{ errorMessage }}
    </p>

    <section>
      <ul
        class="flex flex-col md:flex-row justify-start gap-2 md:gap-4 flex-wrap"
      >
        <li
          class="upload-image bg-grayDetails-300 rounded-lg pr-4 flex justify-between"
          v-for="{ file_name, id, file } of uploadedImages"
          :key="id"
        >
          <div class="flex items-center" style="width: 90%">
            <img
              v-if="isImage(file)"
              :src="file"
              :alt="file_name"
              class="w-[65px] h-[65px] md:w-[100px] md:h-[100px] rounded-l-lg"
            />
            <div
              v-if="isPdf(file)"
              class="hidden md:block w-[100px] h-[100px] overflow-hidden"
            >
              <!-- <iframe
                display:none
                scrolling="no"
                :src="file"
                frameborder="0"
                class="w-[120px] h-[120px] overflow-auto rounded-l-lg hidden md:block"
              ></iframe> -->
              <object
                :data="file"
                class="w-[120px] h-[120px] overflow-auto rounded-l-lg hidden md:block"
              ></object>
            </div>
            <img
              v-if="isPdf(file)"
              src="@/assets/pdf_icon.png"
              alt=""
              class="md:hidden w-[65px] h-[65px] md:w-[100px] md:h-[100px] rounded-l-lg"
            />
            <span
              class="ml-3 text-grayDetails-200 truncate w-[210px] sm:w-auto md:w-auto"
            >
              {{ file_name }}
            </span>
          </div>

          <button
            type="button"
            class="text-[#D23232] font-bold"
            @click="removeImage(id)"
          >
            <XIcon class="w-4" />
          </button>
        </li>
        <li
          v-if="isUploading"
          class="upload-image py-2 px-4 border-2 rounded-md border-grayDetails-100"
        >
          <div class="flex items-center h-full justify-between">
            <div class="w-[80%]">
              <div
                :style="{ width: progressBarValue + '%' }"
                class="bg-grayDetails-100 h-4 animate-pulse rounded-md"
              ></div>
            </div>
            <div
              class="w-4 bg-grayDetails-100 h-4 animate-pulse rounded-full"
            ></div>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<style lang="css" scoped>
.wrapper {
  background-color: transparent;
}

.wrapper form {
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed lightgrey;
  border-radius: 5px;
  gap: 1rem;
}

form :where(p) {
  color: lightgray;
}

.upload-image {
  width: calc((100% - 2 * 1rem) / 3);
}

@media only screen and (max-width: 768px) {
  .upload-image {
    width: 100%;
  }
}

.progress-bar {
  text-align: center;
  padding: 70px 10px;
}
</style>
