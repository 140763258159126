<script setup lang="ts">
import { ref, watch, watchEffect, watchPostEffect } from "vue";
import { computed } from "vue";
import { useField } from "vee-validate";

import { getRepairOptions } from "@/utils/form";
import { useRequestStore } from "@/stores/requestStore";
import { Forms } from "@/interfaces/request.interface";
import { useClientStore } from "@/stores/clientStore";

import CustomButtonGroup from "../CustomButtonGroup.vue";
import AssistanceCoverage from "../AssistanceCoverage.vue";

import BenefitSection from "../BenefitSection.vue";
import RefundSection from "../RefundSection.vue";

const store = useRequestStore();
const clientStore = useClientStore();

const { value: scheme, errorMessage: schemeError } =
  useField<string>("scheme_id");

const {
  value: service,
  errorMessage: serviceError,
  meta: metaService,
} = useField("id_servicio");

const {
  value: formType,
  errorMessage: formTypeError,
  meta: metaFormType,
} = useField<string>("tipo_formulario");

const {
  value: claimDescription,
  errorMessage: claimDescriptionError,
  meta: metaClaimDescription,
} = useField<string>("claim_description");

const coverages = computed(() => store.currentRequest.options);

const activeScheme = ref<string | undefined>("");

watchPostEffect(() => {
  if (coverages.value?.length === 1) {
    activeScheme.value = coverages.value[0].titulo;
  }
});

const coverageOptions = computed(() => {
  return coverages.value.map((option) => option.titulo);
});

watch(
  coverageOptions,
  (newValues) => {
    if (newValues) {
      activeScheme.value = undefined;
      services.value = [];
      activeService.value = null;
      events.value = [];
      activeEvent.value = "";
      clearService();

      scheme.value = "";
      service.value = null;
      claimDescription.value = "";
    }
  },
  { deep: true, flush: "post" }
);

enum Option {
  Assistance = "Realizar Denuncia",
  Benefit = "Solicitar Beneficio",
}

watch(
  activeScheme,
  (newOption) => {
    if (newOption) {
      const coverage = coverages.value.find(
        (option) => option.titulo === newOption
      );

      scheme.value = coverage.scheme_id;

      if (compareCoverageTitle(coverage.titulo, Option.Assistance)) {
        services.value = [];
        activeService.value = null;
        clearService();
        events.value = coverage.options;
        store.setFormType(Forms.Assistance);
        formType.value = Forms.Assistance;
      }

      if (compareCoverageTitle(coverage.titulo, Option.Benefit)) {
        events.value = [];
        activeEvent.value = "";
        services.value = coverage.services;
      }
    }
  },
  { deep: true }
);

const events = ref<string[]>([]);
const activeEvent = ref("");

watch(activeEvent, (newValue) => {
  if (newValue != null) {
    claimDescription.value = newValue;
  }
});

type Service = {
  titulo: string;
  submenu: Array<any>;
};

const services = ref<Service[]>([]);
const activeService = ref();
const activeServiceSubmenu = ref<any[]>([]);

watch(
  activeService,
  (newServiceValue) => {
    if (newServiceValue) {
      clearService();
      resetFormValues();
      activeServiceSubmenu.value = newServiceValue.submenu;
      store.$patch({ emergencyService: newServiceValue.is_emergency });
    }
  },
  { deep: true }
);

type Maybe<t> = t | null | undefined;
const refundMessage = ref<Maybe<string>>(null);
const serviceMessage = ref<Maybe<string>>(null);

const activeSubmenuOption = ref();
const subitemOptions = ref<any[]>([]);

watch(
  activeSubmenuOption,
  (newSubmenuOption) => {
    if (newSubmenuOption) {
      activeSubitem.value = null;
      service.value = null;
      clearRepair();

      subitemOptions.value = newSubmenuOption.submenu ?? [];
      if (!newSubmenuOption.submenu) {
        service.value = newSubmenuOption.servicio_id;
        repairOptions.value = getRepairOptions(newSubmenuOption);
        saveFinalMessages(newSubmenuOption);
      }
    }
  },
  { deep: true }
);

const activeSubitem = ref();

watch(
  activeSubitem,
  (subitemOption) => {
    if (subitemOption) {
      clearRepair();
      if (!subitemOption.submenu) {
        service.value = subitemOption.servicio_id;
        repairOptions.value = getRepairOptions(subitemOption);
        saveFinalMessages(subitemOption);
      }
    }
  },
  { deep: true }
);

const repairOptions = ref<Array<any>>([]);
const activeRepair = ref();
const isBenefit = ref(false);
const isRefund = ref(false);

watch(
  activeRepair,
  (newValue) => {
    isRefund.value = false;
    isBenefit.value = false;

    if (newValue) {
      isBenefit.value = newValue.type === Forms.Benefit && newValue.allowed;
      isRefund.value = newValue.type === Forms.Refund && newValue.allowed;

      if (isBenefit.value) {
        store.setFormType(Forms.Benefit);
        store.setFinalMessage(serviceMessage.value);
        formType.value = Forms.Benefit;
      }

      if (isRefund.value) {
        store.setFormType(Forms.Refund);
        store.setFinalMessage(refundMessage.value);
        formType.value = Forms.Refund;
      }
    }
  },
  { deep: true }
);

const allowedRepairOptions = computed(() => {
  return repairOptions.value.filter((item) => item.allowed);
});

watchEffect(() => {
  if (allowedRepairOptions?.value.length === 1) {
    activeRepair.value = allowedRepairOptions.value[0];
  }
});

function clearService() {
  activeServiceSubmenu.value = [];
  activeSubmenuOption.value = null;
  subitemOptions.value = [];
  activeSubitem.value = null;
  clearRepair();
}

function clearRepair() {
  repairOptions.value = [];
  activeRepair.value = null;
  isRefund.value = false;
  isBenefit.value = false;
  formType.value = "";
}

function resetFormValues() {
  service.value = null;
  formType.value = "";
}

function compareCoverageTitle(firstTitle: string, secondTitle: string) {
  return firstTitle.toLowerCase() === secondTitle.toLowerCase();
}

function saveFinalMessages(subItem: any) {
  refundMessage.value = subItem.habilitado_para_reintegro?.mensaje;
  serviceMessage.value = subItem.habilitado_para_tecnico?.mensaje;
}
</script>

<template>
  <Transition
    enter-active-class="animate__animated animate__fadeIn animate__delay-1s"
    leave-active-class="animate__animated animate__fadeOut animate__fast"
    mode="out-in"
    appear
  >
    <div class="flex flex-col gap-y-5 md:gap-y-11 mb-6 md:mb-11">
      <!-- Coverage Type: Assistance or Benefit -->
      <div v-if="coverageOptions?.length" v-show="clientStore.client !== 'rus'">
        <h1 class="title my-3 text-base md:text-lg 2xl:text-xl">
          ¿En qué podemos ayudarte?
        </h1>
        <div
          class="flex border-section items-center py-4 md:py-6 2xl:py-8 px-4 md:px-8 2xl:px-12 gap-x-16"
        >
          <div class="flex flex-col md:flex-row items-start gap-y-2">
            <!-- {{ forCover }} -->
            <base-radio-group
              v-model="activeScheme"
              name="coverages"
              :options="coverageOptions"
              class="md:ml-12 md:first-of-type:ml-0 capitalize"
            />
          </div>
        </div>
        <p
          class="text-[#D23232] mt-2 font-medium text-xs md:text-sm 2xl:text-base font-montserrat"
          v-if="schemeError"
        >
          {{ schemeError }}
        </p>
      </div>
      <!-- end -->

      <!-- Assistance options -->
      <transition name="slide-fade" mode="out-in">
        <div v-if="events?.length">
          <h1 class="title my-3 text-base md:text-lg 2xl:text-xl">
            ¿Qué pasó?
          </h1>
          <div
            class="grid grid-cols-1 md:grid-cols-2 border-section items-center py-4 2xl:py-8 px-4 md:px-8 2xl:px-12 gap-x-5 2xl:gap-x-9 gap-y-2"
          >
            <CustomButtonGroup :options="events" v-model="activeEvent" />
          </div>
          <p
            class="text-[#D23232] mt-2 font-medium text-xs md:text-sm 2xl:text-base font-montserrat"
            v-if="claimDescriptionError && metaClaimDescription.touched"
          >
            {{ claimDescriptionError }}
          </p>
        </div>
        <!-- Services List -->
        <div v-else-if="services?.length">
          <h1 class="title my-3 text-base md:text-lg 2xl:text-xl">
            ¿Qué servicio necesitás?
          </h1>
          <div
            :class="{
              'gap-1 md:gap-8 2xl:gap-8': activeServiceSubmenu?.length,
            }"
            class="flex flex-col md:border-section md:py-4 2xl:py-8 md:px-8 2xl:px-12"
          >
            <transition name="slide-fade">
              <div
                class="flex flex-col border-section md:border-0 py-4 md:py-0 px-4 md:px-0 md:flex-row items-center gap-x-5 2xl:gap-x-9"
              >
                <CustomButtonGroup
                  :options="services"
                  v-model="activeService"
                  item-text="titulo"
                />
              </div>
            </transition>

            <transition name="slide-fade" mode="out-in" appear>
              <div v-if="activeServiceSubmenu?.length">
                <p class="title my-3 text-base 2xl:text-lg">
                  Seleccioná una opción
                </p>
                <div
                  class="flex flex-col border-section md:border-0 py-4 md:py-0 px-4 md:px-0 md:grid md:grid-cols-4 items-center gap-x-5 2xl:gap-x-9"
                >
                  <CustomButtonGroup
                    :options="activeServiceSubmenu"
                    v-model="activeSubmenuOption"
                    item-text="titulo"
                  />
                </div>
              </div>
            </transition>

            <transition name="slide-fade">
              <div
                class="grid grid-cols-1 md:grid-cols-4 gap-x-9"
                v-show="subitemOptions?.length"
              >
                <CustomButtonGroup
                  :options="subitemOptions"
                  v-model="activeSubitem"
                  item-text="titulo"
                />
              </div>
            </transition>
          </div>

          <p
            class="text-[#D23232] mt-2 text-xs md:text-sm 2xl:text-base font-medium font-montserrat"
            v-if="serviceError && metaService.touched"
          >
            {{ serviceError }}
          </p>
        </div>
        <!-- end -->
      </transition>
      <!-- end -->

      <!-- Only show if the coverage selected is ASSISTANCE -->
      <transition name="slide-fade">
        <div v-if="activeEvent">
          <assistance-coverage />
        </div>
      </transition>
      <!-- end -->

      <!-- Only show if the coverage is benefit and exists a service selected  -->
      <transition name="slide-fade">
        <div v-if="allowedRepairOptions?.length">
          <h1 class="title my-3 text-base md:text-lg 2xl:text-xl">
            ¿Quién realiza el trabajo?
          </h1>
          <div
            class="grid grid-cols-1 md:grid-cols-2 border-section py-4 px-4 md:px-8 2xl:px-12 gap-x-5 2xl:gap-x-9"
          >
            <CustomButtonGroup
              :options="allowedRepairOptions"
              v-model="activeRepair"
              item-text="titulo"
            />
          </div>
          <p
            class="text-[#D23232] mt-2 font-medium text-xs md:text-sm 2xl:text-base font-montserrat"
            v-if="formTypeError && metaFormType.touched"
          >
            {{ formTypeError }}
          </p>
        </div>
      </transition>
      <!-- end -->

      <transition name="slide-fade">
        <div v-if="isBenefit">
          <benefit-section />
        </div>
      </transition>

      <transition name="slide-fade">
        <div v-if="isRefund">
          <refund-section :form-type="formType" />
        </div>
      </transition>
    </div>
  </Transition>
</template>

<style scoped>
@media only screen and (max-width: 768px) {
  input {
    width: 100%;
    font-size: 12px;
  }

  select {
    width: 100%;
    font-size: 12px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  left: 2rem;
  opacity: 0;
}

select {
  background: none;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.2s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
