export const useFormatDate = () => {
  const formatDate = (date: string) => {
    const newDate = new Date(date);
    return Intl.DateTimeFormat("es-AR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(newDate);
  };

  const formatDateTime = (date: string) => {
    const newDate = new Date(date);

    return new Intl.DateTimeFormat("es-AR", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(newDate);
  };

  return { formatDate, formatDateTime };
};
