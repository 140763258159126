<script lang="ts">
export default {
  name: "AlertOutline",
};
</script>
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27 3L3 8.27V15.73L8.27 21H15.73C17.5 19.24 21 15.73 21 15.73V8.27L15.73 3H8.27ZM9.1 5H14.9L19 9.1V14.9L14.9 19H9.1L5 14.9V9.1L9.1 5ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
      fill="#D23232"
    />
  </svg>
</template>

<style lang="scss" scoped></style>
