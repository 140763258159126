<script lang="ts">
export default {
  name: "DownloadIcon",
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    viewBox="0 0 24 24"
    class="fill-current text-grayDetails-200"
  >
    <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
  </svg>
</template>
