import { getCurrentInstance } from "vue";

export const useFormComponent = (props: any) => {
  const { emit } = getCurrentInstance() as any;

  const updateValue = (event: Event) => {
    const evt = event.target as HTMLInputElement;
    let val: string | boolean = evt.value;

    if (evt.type === "checkbox") val = evt.checked;
    if (evt.type === "radio") val = props.value;

    emit("update:modelValue", val);
  };

  return { updateValue };
};
