<script lang="ts">
export default {
  name: "CoverageIcon",
};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path
      d="M12.3,1.6C12.7,1.7,13,2,13.4,2.3C14.6,3.4,16,4,17.5,4.3c0.4,0.1,0.9,0.1,1.3,0.2c1.2,0.1,2,1,2,2.2c0,1.5,0,3,0,4.6
	c-0.1,2-0.5,3.9-1.5,5.7c-0.9,1.6-2,2.8-3.5,3.8c-1,0.7-2.1,1.2-3.2,1.6c-0.3,0.1-0.7,0.1-1,0c-1.9-0.7-3.7-1.6-5.2-3.1
	c-1.4-1.4-2.3-3.1-2.7-5.1c-0.4-1.7-0.5-3.4-0.5-5.1c0-0.8,0-1.6,0-2.4c0-1.2,0.9-2.3,2.2-2.3c1,0,1.9-0.2,2.9-0.6
	c0.9-0.3,1.7-0.8,2.4-1.5C11,2,11.3,1.7,11.8,1.6C11.9,1.6,12.1,1.6,12.3,1.6z M4.4,8.9c0,1.7,0.1,3.3,0.4,4.9
	c0.5,2,1.5,3.8,3.1,5.2c1.2,1,2.5,1.7,4,2.2c0.1,0,0.2,0,0.3,0c0.9-0.3,1.8-0.7,2.7-1.3c1.6-1,2.8-2.3,3.6-4c0.7-1.5,1-3,1.1-4.6
	c0.1-1.6,0.1-3.1,0-4.7c0-0.5-0.4-0.9-0.8-0.9c-0.6-0.1-1.3-0.1-1.9-0.3C15.1,5,13.6,4.2,12.3,3C12,2.7,12,2.7,11.7,3
	c-1.3,1.2-2.8,2-4.6,2.4C6.6,5.5,5.9,5.5,5.3,5.6c-0.6,0.1-0.9,0.5-0.9,1C4.4,7.4,4.4,8.2,4.4,8.9z M12,6.8c2.8,0,5.2,2.3,5.2,5.2
	c0,2.8-2.4,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2C6.8,9.1,9.1,6.8,12,6.8z M12,8c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4
	C16,9.8,14.2,8,12,8z M10.5,12.2c-0.3-0.3-0.6-0.3-0.9,0c-0.2,0.2-0.2,0.6,0,0.9c0.3,0.3,0.7,0.7,1,1c0.3,0.3,0.6,0.3,0.9,0
	c0.9-0.9,1.9-1.9,2.8-2.8c0,0,0,0,0.1-0.1c0.3-0.3,0.1-0.8-0.3-1c-0.3-0.1-0.5,0-0.7,0.2c-0.7,0.7-1.5,1.5-2.2,2.2
	c-0.1,0.1-0.1,0.1-0.1,0.2C10.9,12.6,10.7,12.4,10.5,12.2z"
    />
  </svg>
</template>
