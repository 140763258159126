import { useField } from "vee-validate";
import type { Insured } from "@/interfaces/product.interface";

export const useAutocompleteFields = () => {
  const { value: phone } = useField("cellphone_number");
  const { value: alternativePhone } = useField("backup_cellphone_number");
  const { value: email } = useField("email");
  const { value: province } = useField("province");
  const { value: city } = useField("city");
  const { value: street_name } = useField("street_name");
  const { value: street_number } = useField("street_number");
  const { value: floor } = useField("floor");
  const { value: department } = useField("department");
  const { value: holder_person } = useField("holder_person");
  const { value: cuil } = useField("cuil");
  const { value: cbu } = useField("cbu");

  const autocomplete = (insuredInfo: Insured) => {
    phone.value = insuredInfo.cellphone_number;
    alternativePhone.value = insuredInfo.telefono_respaldo;
    email.value = insuredInfo.email;
    province.value = insuredInfo.province?.name;
    city.value = insuredInfo.city?.name;
    street_name.value = insuredInfo.street_name;
    street_number.value = insuredInfo.street_number;
    floor.value = insuredInfo.floor;
    department.value = insuredInfo.department;
    holder_person.value = `${insuredInfo.name} ${insuredInfo.last_name}`;
    cuil.value = insuredInfo.cuil;
    cbu.value = insuredInfo.cbu;
  };

  return {
    autocomplete,
  };
};
