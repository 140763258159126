export const getRamoName = (codRamo: any) => {
  const names: { [key: string]: string } = {
    HG: "Hogar",
    IC: "Asistencia Comercio",
    CL: "Celular",
    BI: "Bicicleta",
    MA: "Mascota",
    SA: "Salud",
  };
  return names[codRamo] || "Otro";
};
