<script setup lang="ts">
import { computed } from "vue";
import { useForm } from "vee-validate";
import { ref } from "vue";
import type { ObjectSchema } from "yup";
import type { ObjectShape } from "yup/lib/object";

import { useRequestStore } from "@/stores/requestStore";
import { Forms } from "@/interfaces/request.interface";

import DetailInformation from "./formSteps/DetailInformation.vue";
import PersonalInformation from "./formSteps/PersonalInformation.vue";
import RequestInformation from "./formSteps/RequestInformation.vue";
import TabHeader from "./TabHeader.vue";
import FormButtonGroup from "./FormButtonGroup.vue";
import FormFooter from "./FormFooter.vue";

const emit = defineEmits<{
  (e: "next", formValue: object): void;
  (e: "submit", formValue: object): void;
}>();

const props = defineProps<{
  validationSchema: ObjectSchema<ObjectShape>[];
  initialValues: any;
}>();

const store = useRequestStore();

const currentStep = ref(0);
const formData = ref({});

const steps = [
  {
    Component: PersonalInformation,
    id: 1,
  },
  {
    Component: RequestInformation,
    id: 2,
  },
  {
    Component: DetailInformation,
    id: 3,
  },
];

const hasPrevious = computed(() => {
  return currentStep.value > 0;
});

const isLastStep = computed(() => {
  return currentStep.value === steps.length - 1;
});

const isRefund = computed(() => {
  return currentStep.value === 1 && store.formType === Forms.Refund;
});

const currentSchema = computed(() => {
  return props.validationSchema[currentStep.value];
});

const { handleSubmit } = useForm({
  validationSchema: currentSchema,
  initialValues: props.initialValues,
});

const onSubmit = handleSubmit((values) => {
  formData.value = {
    ...formData.value,
    ...values,
  };

  if (isRefund.value) {
    emit("submit", formData.value);
    return;
  }

  if (!isLastStep.value) {
    currentStep.value++;
    emit("next", formData.value);

    return;
  }

  emit("submit", formData.value);
});

const goToPrev = () => {
  if (currentStep.value === 0) return;

  currentStep.value--;
};
</script>

<template>
  <div
    class="flex flex-col flex-grow justify-start py-5 md:py-10 px-5 md:px-24 gap-5 md:gap-10"
  >
    <tab-header :step="currentStep" />

    <form @submit.prevent="onSubmit">
      <keep-alive>
        <component :is="steps[currentStep].Component"></component>
      </keep-alive>

      <Transition
        enter-active-class="animate__animated animate__fadeIn animate__delay-1s"
        leave-active-class="animate__animated animate__fadeOut animate__fast"
        mode="out-in"
        appear
        :key="steps[currentStep].id"
      >
        <form-button-group
          :is-last-step="isLastStep"
          :is-refund="isRefund"
          :has-previous="hasPrevious"
          @click="goToPrev"
        />
      </Transition>
    </form>
    <Transition
      enter-active-class="animate__animated animate__fadeIn animate__delay-1s"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
      mode="out-in"
      appear
      :key="steps[currentStep].id"
    >
      <form-footer />
    </Transition>
  </div>
</template>

<style scoped></style>
