<script setup lang="ts">
import { RouterLink } from "vue-router";
import { computed } from "vue";
import { useFormatDate } from "@/composables/formatDate";
import type { Request } from "@/interfaces/product.interface";
import { EyeIcon } from "@heroicons/vue/outline";

const props = defineProps<{
  request: Request;
  requestPath?: boolean;
}>();

const statusColor = computed<string>(() => {
  const { estado_actual } = props.request;
  if (estado_actual === "EN CURSO") return "text-yellow-500";
  if (estado_actual === "REALIZADO") return "text-green-500";
  if (estado_actual === "CANCELADO") return "text-red-500";
  return "";
});

const { formatDate } = useFormatDate();
</script>
<template>
  <tr>
    <td class="md:table-cell">
      {{ request.nro_gestion }}
    </td>
    <td class="hidden md:table-cell">{{ request.tipo_pedido }}</td>
    <td class="md:table-cell" v-if="requestPath">
      {{ request.producto?.title }}
    </td>
    <td class="hidden md:table-cell">
      {{ request.poliza }}
    </td>
    <td class="md:table-cell">
      {{ formatDate(request.fecha_inicio) }}
    </td>
    <td
      class="capitalize-first md:table-cell"
      :class="statusColor"
      style="font-weight: 700"
    >
      {{ request.estado_actual.toLowerCase() }}
    </td>
    <td class="md:table-cell">
      <button class="text-grayDetails-200">
        <RouterLink
          class="text-grayDetails-200"
          :to="{
            name: 'RequestView',
            params: { id: request.id },
            query: { nro_gestion: request.nro_gestion },
          }"
        >
          <EyeIcon
            class="w-4 md:w-[24px] cursor-pointer md:h-[24px] hover:text-skin-major"
          />
        </RouterLink>
      </button>
    </td>
  </tr>
</template>
<style scoped>
@media (max-width: 600px) {
  tr td {
    font-size: 12px;
  }
}
.capitalize-first::first-letter {
  text-transform: uppercase;
}
</style>
