import type { Request } from "@/interfaces/product.interface";
import { Forms } from "@/interfaces/request.interface";

export const inputSearch = (array: Request[], search: string): Request[] => {
  const newArray = array.filter(
    (item) =>
      String(item.poliza).toLowerCase().includes(search) ||
      String(item.nro_gestion).toLowerCase().includes(search) ||
      item.producto?.title.toLowerCase().includes(search) ||
      item.tipo_pedido.toLowerCase().includes(search)
  );

  return newArray;
};

export const statusSearch = (array: Request[], status: string): Request[] => {
  const newArray = array.filter(
    (item) => String(item.estado_actual).toLowerCase() === status.toLowerCase()
  );

  return newArray;
};

export const dateSearch = (
  array: Request[],
  startDate?: string,
  endDate?: string
): Request[] => {
  let newArray: Request[] = [];

  newArray = array.filter((item) => {
    const itemDate = new Date(
      item.fecha_inicio.replace(/-/g, "/").replace(/T.+/, "")
    );

    if (startDate && !endDate) {
      return itemDate >= formatDate(startDate);
    }

    if (!startDate && endDate) {
      return itemDate <= formatDate(endDate);
    }

    if (startDate && endDate) {
      return (
        itemDate >= formatDate(startDate) && itemDate <= formatDate(endDate)
      );
    }
  });

  return newArray;
};

const formatDate = (date: string) => {
  return new Date(date.replace(/-/g, "/"));
};

export const getRepairOptions = (option: any) => {
  const requestTechnician = {
    ...option.habilitado_para_tecnico,
    type: Forms.Benefit
  };

  const refund = {
    ...option.habilitado_para_reintegro,
    type: Forms.Refund
  };

  return [requestTechnician, refund] ?? [];
};
