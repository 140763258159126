import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import { signInUserAndAutocompleteRefundForm } from "../services/refund.services";
import { AxiosError } from "axios";
import { useAuthStore } from "./authStore";
import { useRouter } from "vue-router";
import { useCookies } from "@vueuse/integrations/useCookies";

export const useRefundStore = defineStore("refund", () => {
  const authStore = useAuthStore();
  const { user, token } = storeToRefs(authStore);

  const clientDefaultValues = ref<{
    fullName: string;
    cbu: string;
    company: string | null;
    cuil: string;
    email: string;
    pedidoId: number;
    policy: number;
    service: string;
    mustUploadDocumentation: boolean;
  } | null>(null);

  const errorLoadingForm = ref(false);

  const router = useRouter();

  const {
    get: getCookie,
    set: setCookie,
    remove: removeCookie,
  } = useCookies(["refund-key"], {
    doNotParse: false,
    autoUpdateDependencies: false,
  });

  async function redirectUserToRefundForm({ key }: { key: string }) {
    setCookie("refund-key", key);

    try {
      const { data } = await signInUserAndAutocompleteRefundForm(key);
      console.log({ data });
      user.value = data.user;
      token.value = data.user.access_token;
      clientDefaultValues.value = parseClientInformation(data.form_data);
      errorLoadingForm.value = false;

      router.push({ name: "RefundFormView" });
    } catch (error) {
      errorLoadingForm.value = true;
      if (error instanceof AxiosError) {
        console.log(error.response?.data);
        throw new Error("Error al redireccionar al formulario de reintegro");
      }
    }
  }

  function parseClientInformation(clientInfo: any) {
    return {
      company: clientInfo.compañia,
      fullName: clientInfo?.nombre_titular + " " + clientInfo?.apellido_titular,
      cbu: clientInfo.cbu,
      cuil: clientInfo.cuil,
      email: clientInfo.email,
      pedidoId: clientInfo.pedido_id,
      policy: clientInfo.poliza,
      service: clientInfo.servicio,
      mustUploadDocumentation: clientInfo.requiere_documentacion,
    };
  }

  return {
    redirectUserToRefundForm,
    getCookie,
    removeCookie,
    clientDefaultValues,
    errorLoadingForm,
  };
});
