<script setup lang="ts">
import { useClientStore } from "@/stores/clientStore";

const clientStore = useClientStore();
const phoneCall = clientStore.formatPhoneNumber;
</script>
<template>
  <div
    v-if="clientStore.client === 'rus'"
    class="flex flex-col items-center gap-3 md:gap-6"
  >
    <img src="@/assets/logo_rus.svg" alt="RUS logo" class="w-32 md:w-[12rem]" />
    <p class="font-montserrat text-xs md:text-base font-medium text-center">
      Por otras consultas sobre tu póliza <br class="block md:hidden" />
      podés comunicarte con <b>RUS</b> al
      <a :href="phoneCall" target="_blank" rel="noopener noreferrer"
        ><b class="text-link">0800-888-7787</b></a
      >
    </p>
  </div>
</template>

<style scoped></style>
