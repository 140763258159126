<script lang="ts">
export default {
  name: "HomeIcon",
};
</script>

<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 139.8 139.8"
    class="fill-current"
    xml:space="preserve"
  >
    <path
      d="M128.5,61.8L128.5,61.8L78,11.3c-4.4-4.5-11.6-4.5-16.1,0c0,0,0,0,0,0L11.3,61.8v0c-4.4,4.5-4.4,11.7,0,16.1
		c2,2,4.7,3.2,7.5,3.3c0.1,0,0.2,0,0.4,0h2v37.2c0,7.4,6,13.3,13.4,13.4h19.7c2,0,3.7-1.6,3.7-3.6c0,0,0,0,0,0V99
		c0-3.3,2.7-6,6.1-6.1h11.7c3.4,0,6.1,2.7,6.1,6.1v29.2c0,2,1.6,3.6,3.6,3.6l0,0h19.8c7.4,0,13.3-6,13.4-13.4V81.3h1.9
		c6.3,0,11.4-5.1,11.4-11.4C131.8,66.9,130.6,64,128.5,61.8z M123.4,72.8c-0.8,0.8-1.8,1.2-2.9,1.2h-5.5c-2,0-3.6,1.6-3.6,3.6v40.8
		c0,3.4-2.7,6.1-6.1,6.1l0,0H89.1V99c0-7.4-6-13.3-13.3-13.4H64.1c-7.4,0-13.3,6-13.4,13.4v25.5H34.5c-3.4,0-6.1-2.7-6.1-6.1V77.7
		c0-2-1.6-3.6-3.6-3.7c0,0,0,0,0,0h-5.6c-1.1,0-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2,0-5.9c0,0,0,0,0,0l0,0L67,16.4
		c1.6-1.6,4.2-1.6,5.8,0c0,0,0,0,0,0l50.5,50.5l0,0C125,68.6,125,71.2,123.4,72.8C123.4,72.8,123.4,72.8,123.4,72.8z"
    />
  </svg>
</template>
