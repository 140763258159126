<script lang="ts">
export default {
  name: "ChevronLeftIcon",
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-3 w-3 md:h-4 md:w-4"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-width="2"
  >
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
  </svg>
</template>
