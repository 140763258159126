<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useField, useForm } from "vee-validate";
import { computed, onMounted, ref } from "vue";
import { number, object, string } from "yup";

import LogoRus from "@/components/logos/LogoRus.vue";
import ComunicationSection from "@/components/mobile/Login/ComunicationSection.vue";
import type { ItemOption } from "@/interfaces/Inputs";
import { getDocumentsType } from "@/services/auth.services";
import { ClientName, useClientStore } from "@/stores/clientStore";
import { useAuthStore } from "../../../stores/authStore";
import AlertOutline from "@/components/icons/AlertOutline.vue";

const clientStore = useClientStore();
const {
  client,
  whatsappNumber: whatsapp,
  phoneNumber: phone,
} = storeToRefs(clientStore);

const authStore = useAuthStore();
const loginError = ref("");

const phoneCall = clientStore.formatPhoneNumber;
const whatsappCall = clientStore.formatWhatsappNumber;

const dniTypes = ref<ItemOption[]>([]);

const clientTheme = computed(() => {
  if (client.value === ClientName.RioUruguaySeguros) {
    return "theme-rus";
  }
  if (client.value === ClientName.Galicia) {
    return "theme-galicia";
  }
  return "theme-rapi-hogar";
});

const clientBg = computed(() => {
  if (client.value === ClientName.RioUruguaySeguros) {
    return "bg-right bg-inicio-sesion-rus bg-desktop-size-rus";
  }
  if (client.value === ClientName.Galicia) {
    return "bg-inicio-sesion-galicia bg-desktop-size-galicia";
  }
  return "bg-inicio-sesion-rapi bg-desktop-size-rh";
});

const disabledContact = computed(() => {
  return client.value === ClientName.Rapihogar || client.value === "";
});

const whatsappNumber = whatsapp.value;
const phoneNumber = phone.value;

onMounted(async () => {
  const response = await getDocumentsType();
  // dniTypes.value = response.data;
  dniTypes.value = response.data.filter(
    (type) =>
      type.name === "CUIL" || type.name === "DNI" || type.name === "CUIT"
  );
});

const validationSchema = object({
  dniType: number().required("Este campo es requerido"),
  dni: string()
    .required("Este campo es requerido")
    .min(7, "El dni debe tener al menos 7 caracteres"),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const { value: dni } = useField("dni");
const { value: dniType } = useField("dniType");

const submitFirstStep = handleSubmit((values) => {
  authStore
    .sendChallenge({
      document_type: values.dniType,
      document_number: values.dni,
      org_id: authStore.organizationClientId,
    })
    .catch((error) => {
      if (error.status === 429) {
        loginError.value =
          "Se han realizado intentos de inicio de sesión fallidos. Por favor, inténtalo de nuevo más tarde.";
      } else if (error.status === 500) {
        loginError.value = "No se encuentra registrado en nuestro sistema";
      } else {
        const { detail } = error.data;
        loginError.value = detail;
      }
      setTimeout(() => {
        loginError.value = "";
      }, 60000);
    });
});
</script>

<template>
  <div
    class="pt-0 flex flex-col md:flex-row flex-grow md:justify-between items-center bg-white md:bg-no-repeat md:bg-cover w-full h-auto md:h-full md:bg-center overflow-hidden"
    :class="clientTheme"
  >
    <div
      v-if="client === 'rapihogar' || client === ''"
      class="md:hidden w-full"
    >
      <div
        class="ellipse-border-mobile bg-size-rh bg-inicio-mobile-rapi h-[248px]"
      ></div>
    </div>
    <div class="md:hidden w-full" v-if="client === 'rus'">
      <div
        class="ellipse-border-mobile bg-size-rus bg-inicio-mobile-rus h-[248px]"
      ></div>
    </div>
    <div v-if="client === 'galicia'" class="md:hidden w-full">
      <div
        class="ellipse-border-mobile bg-size-galicia bg-inicio-mobile-galicia h-[248px]"
      ></div>
    </div>
    <div
      class="md:w-[388px] w-[90%] md:mx-auto flex flex-col items-center space-y-4 md:space-y-8 bg-white rounded-md p-0 md:p-8 pt-0"
    >
      <div>
        <LogoRus
          svgColor=""
          v-if="client === 'rus'"
          class="hidden md:block md:w-56 2xl:w-[276px]"
        />
        <h2
          class="md:mt-[42px] text-center text-2xl md:text-2xl 2xl:text-3xl title"
        >
          Iniciar sesión
        </h2>
      </div>

      <!-- <div
        class="text-primary text-[#D23232] md:w-[140%] text-center"
        v-if="loginError"
      >
        {{ loginError }}
      </div> -->

      <form @submit.prevent="submitFirstStep" class="w-full">
        <fieldset>
          <legend class="hidden">User data</legend>
          <div class="mb-4">
            <BaseSelect
              :options="dniTypes"
              v-model="dniType"
              :error="errors.dniType"
            />
          </div>

          <BaseInput
            type="text"
            class="text-grayDetails-400"
            v-model.trim="dni"
            placeholder="Número de documento"
            :error="errors.dni"
          />
        </fieldset>

        <button
          type="submit"
          class="mt-6 md:mt-[59px] btn btn-salmon py-[14px] md:py-3 text-[15px] bg-skin-major hover:bg-skin-button-bg-hover"
        >
          Ingresar
        </button>
      </form>
      <div
        v-if="loginError"
        class="flex gap-x-4 border border-[#D23232] bg-[#d232321f] p-4 rounded-md mt-0"
      >
        <div>
          <AlertOutline />
        </div>
        <div class="text-[#D23232] font-normal text-xs leading-6">
          <p>
            El DNI o CUIL ingresado no está registrado. Si estás adherido
            comunicate con nosotros
            <span class="hidden md:inline-block">vía</span>
          </p>
          <p class="hidden md:block">
            <a :href="whatsappCall" rel="noopener noreferrer" target="_blank">
              <span class="text-link font-bold">WhatsApp</span></a
            >
            ({{ whatsappNumber }}) o por
            <a :href="phoneCall" rel="noopener noreferrer" target="_blank">
              <span class="text-link font-bold"> teléfono </span></a
            >
            ({{ phoneNumber }}).
          </p>
        </div>
      </div>
      <ComunicationSection v-if="!disabledContact" />
    </div>
    <div class="hidden md:block w-[65%] h-full">
      <div class="w-full h-full" :class="clientBg"></div>
    </div>
  </div>
</template>

<style scoped>
.ellipse-border-mobile {
  display: block;
  box-sizing: border-box;
  clip-path: ellipse(85% 90% at 50% 0%);
  background-size: 140%;
  background-repeat: no-repeat;
  background-position: right;
}
.bg-size-rh {
  background-size: 110%;
}
.bg-size-rus {
  background-size: 110%;
}
.bg-size-galicia {
  background-size: 105%;
}
.bg-desktop-size-rh {
  background-size: cover;
}
.bg-desktop-size-rus {
  background-size: cover;
  background-position: center;
}
.bg-desktop-size-galicia {
  background-size: cover;
}

@media screen and (min-width: 959px) {
  .bg-desktop-size-rus {
    background-size: cover;
    background-position: right;
  }
}

@media screen and (min-width: 1090px) {
  .bg-desktop-size-rus {
    background-size: cover;
    background-position: left;
  }
}
</style>
