<script setup lang="ts">
import { ref, computed, provide, watch } from "vue";
import { createRequest } from "@/services/request.services";
import {
  schema_1,
  schema_2,
  step2Refund,
  step2Benefit,
  step2Assistance,
  schema_3,
  step3Assistance,
  step3Benefit,
} from "@/validations/validations-scheme";

import ApplicationForm from "@/components/dashboard/request/ApplicationForm.vue";

import { useRequestStore } from "@/stores/requestStore";

import { useRouter } from "vue-router";
import { useForm } from "vee-validate";
import { Forms } from "@/interfaces/request.interface";
import Modal from "@/components/Modal.vue";

const notifications = [
  {
    title: "Recibimos tu pedido",
    body: "Realizaremos el reintegro en los próximos 3 días hábiles. Recordá que el importe a devolver está sujeto al monto disponible en tu tope anual.",
  },
  {
    title: "Recibimos tu pedido",
    body: "A la brevedad te estaremos contactando por teléfono o WhatsApp para coordinar la visita del técnico.",
  },
  {
    title: "Recibimos tu denuncia",
    body: "¡Tu denuncia se cargó con éxito!. A la brevedad nos estaremos comunicando con vos por teléfono o WhatsApp.",
  },
];

const reqStore = useRequestStore();
const router = useRouter();

const currentForm = computed(() => reqStore.formType);

const finalMessage = computed(() => reqStore.finalMessage);

const initialValues = computed(() => {
  return reqStore.initialValues;
});

const validationSchema = computed(() => {
  if (currentForm.value === Forms.Refund) {
    return [schema_1, step2Refund];
  }
  if (currentForm.value === Forms.Benefit) {
    return [schema_1, step2Benefit, step3Benefit];
  }
  if (currentForm.value === Forms.Assistance) {
    return [schema_1, step2Assistance, step3Assistance];
  }
  return [schema_1, schema_2, schema_3];
});

const { resetForm } = useForm();
const showNotification = ref(false);

const successMessage = ref({
  title: "",
  body: "",
});

const successMsg = computed(() => {
  let message: any = {};
  if (currentForm.value === Forms.Refund) {
    message = notifications[0];
  }
  if (currentForm.value === Forms.Benefit) {
    message = notifications[1];
  }
  if (currentForm.value === Forms.Assistance) {
    message = notifications[2];
  }
  return message;
});

const errorMessage = ref("");
const hasError = ref(false);
const isLoading = ref(false);
provide("LOADING", isLoading);

async function onSubmit(formData: any) {
  isLoading.value = true;

  if (currentForm.value === Forms.Refund) {
    const { availability, holder_person, ...newFormData } = formData;
    await createRequest(newFormData)
      .then(() => {
        isLoading.value = false;
        hasError.value = false;
        errorMessage.value = "";
        successMessage.value = successMsg.value;
        showNotification.value = true;
      })
      .catch((error) => {
        isLoading.value = false;
        hasError.value = true;
        errorMessage.value = error.data;
        showNotification.value = true;
      });

    return;
  }

  let formValues: any;

  if (currentForm.value === Forms.Benefit) {
    const { holder_person, cuil, cbu, province, city, ...newData } = formData;
    formValues = newData;
  }

  if (currentForm.value === Forms.Assistance) {
    const {
      holder_person,
      cuil,
      cbu,
      province,
      city,
      id_servicio,
      availability,
      imagenes,
      ...newData
    } = formData;
    formValues = newData;
  }

  await createRequest(formValues)
    .then(() => {
      isLoading.value = false;
      hasError.value = false;
      errorMessage.value = "";
      successMessage.value = successMsg.value;
      showNotification.value = true;
    })
    .catch((error) => {
      isLoading.value = false;
      hasError.value = true;
      errorMessage.value = Object.values(error.data)
        ? Object.values(error.data)[0]
        : error.data;
      showNotification.value = true;
    });
}

function closeModal() {
  if (hasError.value) {
    showNotification.value = false;
    return;
  }

  showNotification.value = false;
  reqStore.setFormType("");
  reqStore.setFinalMessage("");
  router.push({ name: "Requests" });
  resetForm();
}

watch(isLoading, (newValue) => {
  document.body.style.overflow = newValue ? "hidden" : "auto";
});
</script>

<template>
  <application-form
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    @submit="onSubmit"
  />

  <div v-if="isLoading" class="modal-mask"></div>

  <Teleport v-if="showNotification" to="body">
    <Modal :isOpen="showNotification" @close-modal="closeModal">
      <template #header>
        <div class="flex justify-center">
          <span class="title text-base md:text-2xl">
            {{ successMessage.title }}
          </span>
        </div>
      </template>

      <template #body>
        <div
          class="text-primary text-sm md:text-base flex justify-center items-center text-grayDetails-400"
        >
          <span class="text-center" v-if="!hasError">
            {{ finalMessage ?? successMessage.body }}
          </span>

          <span class="text-center" v-else> {{ errorMessage }} </span>
        </div>
      </template>

      <template #footer>
        <button
          class="btn btn-salmon btn-transition bg-skin-major hover:bg-skin-button-bg-hover px-5 py-3 w-48 md:w-40"
          type="button"
          @click="closeModal"
        >
          Aceptar
        </button>
      </template>
    </Modal>
  </Teleport>
</template>

<style scoped>
input:checked + label {
  color: blue;
  font-weight: bold;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
</style>
