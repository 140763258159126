<template>
  <div class="flex flex-col w-full border-none p-0">
    <div class="flex items-center gap-1">
      <label
        :for="name"
        class="title text-grayDetails-400 text-sm 2xl:text-base"
        >{{ label }}</label
      >
      <span class="font-medium" v-show="hint">{{ hint }}</span>
    </div>

    <div class="relative">
      <input
        v-bind="$attrs"
        :disabled="readOnly"
        :name="name"
        :id="id"
        :value="inputValue"
        type="date"
        @input="handleChange"
        @blur="handleBlur"
        class="base-input w-full"
      />
      <div
        class="absolute inset-y-0 right-[1px] flex items-center bg-grayDetails-200 rounded-md pointer-events-none"
      >
        <img
          src="@/assets/calendar.svg"
          alt="calendar"
          class="md:h-[22px] md:w-[22px] h-5 w-5 mx-2 2xl:mx-3"
        />
      </div>
    </div>
    <BaseErrorMessage
      :id="id ? id : name"
      class="font-medium font-montserrat text-xs md:text-sm 2xl:text-base"
      v-show="errorMessage && meta.touched"
    >
      {{ errorMessage }}
    </BaseErrorMessage>
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import { toRef } from "vue";

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  hint: {
    type: String,
    default: "",
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const name = toRef(props, "name");

const {
  value: inputValue,
  errorMessage,
  handleChange,
  handleBlur,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  left: 2rem;
  z-index: 10;
  display: block;
  width: 20px;
  margin: 0px;
  padding: 0px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
}
</style>
