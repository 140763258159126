<script setup lang="ts">
import { useUniqueID } from "@/composables/uniqueID";
import { useFormComponent } from "@/composables/SetupFormComponent";
import { useAttrs, type PropType } from "vue";
import type { ItemOption } from "@/interfaces/Inputs";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";

const props = defineProps({
  label: { type: String, default: "" },
  modelValue: { type: [String, Number], default: "" },
  options: { type: Array as PropType<ItemOption[]>, required: true },
  error: { type: String, default: "" },
});

const attrs = useAttrs();

const { updateValue } = useFormComponent(props);
const uuid = useUniqueID().getID();
</script>

<template>
  <div class="flex flex-col w-full border-none p-0">
    <div class="flex gap-1">
      <label :for="uuid" v-if="props.label">{{ props.label }}</label>
    </div>
    <div class="relative group">
      <select
        class="field base-input w-full text-grayDetails-100"
        :class="{'border-red-500': props.error }"
        v-bind="{ ...attrs, onChange: updateValue }"
        :value="modelValue"
        :id="uuid"
        :aria-describedby="props.error ? `${uuid}-error` : undefined"
        :aria-invalid="props.error ? true : false"
      >
        <option
          class="text-grayDetails-400"
          v-for="option in props.options"
          :key="option.id"
          :value="option.id"
          :selected="option.id === props.modelValue"
        >
          {{ option.name }}
        </option>
      </select>
      <div
        class="absolute inset-y-0 right-2 flex items-center pointer-events-none"
      >
        <ChevronRightIcon
          class="h-[24px] w-[24px] mx-3 text-grayDetails-100 group-active:rotate-90 duration-300"
        />
      </div>
    </div>
    <BaseErrorMessage
      class="font-medium text-[15px] 2xl:text-base font-montserrat"
      v-if="props.error"
      :id="`${uuid}-error`"
    >
      {{ props.error }}
    </BaseErrorMessage>
  </div>
</template>
<style scoped>
select {
  background: none;
}
</style>
