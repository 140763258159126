<template>
  <button
    class="btn flex justify-center items-center my-1 md:my-2 bg-grayDetails-300 border-2 h-9 md:h-11 border-grayDetails-100 text-grayDetails-400"
    :class="{
      'text-white font-bold bg-[#566FF4]': isActive,
    }"
    type="button"
    @click="$emit('update:modelValue', value)"
  >
    {{ label }}
  </button>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: { type: [String, Number, Object], default: "" },
  value: { type: [String, Number, Object], default: "" },
  label: { type: [String, Number], default: "" },
});

const isActive = computed(() => {
  if (typeof props.value === "object") {
    return JSON.stringify(props.value) === JSON.stringify(props.modelValue);
  } else {
    return props.modelValue === props.value;
  }
});
</script>

<style lang="css" scoped>
.bg-red {
  background: red;
}
</style>
