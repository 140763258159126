import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: import.meta.env.VITE_CLIENT_NAME,
  fallbackLocale: "rapihogar",
  availableLocales: ["rapihogar", "galicia"],
  messages: messages,
});
