<script lang="ts">
export default {
  name: "CheckIcon",
};
</script>

<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.6 20.6"
  >
    <path
      class="fill-current text-current"
      d="M7.09,14.17,2.92,10,1.5,11.41,7.09,17l12-12L17.68,3.59Z"
    />
  </svg>
</template>
