<script setup lang="ts">
import { useClientStore } from "@/stores/clientStore";

import { XIcon, ExclamationCircleIcon } from "@heroicons/vue/solid";

const props = defineProps({
  showMessage: { type: Boolean, required: true },
});

defineEmits<{
  (e: "closeMessage"): void;
}>();

const clientStore = useClientStore();

const whatsappCall = clientStore.formatWhatsappNumber;
const phoneCall = clientStore.formatPhoneNumber;
</script>
<template>
  <Transition name="message">
    <div
      v-if="props.showMessage && clientStore.client === 'rus'"
      class="container w-[90%] flex justify-between fixed md:max-w-[44rem] top-36 z-10 border-section border-blueRus-100 border-opacity-50 bg-blue-50 p-4 shadow-md"
    >
      <ExclamationCircleIcon
        class="hidden md:block w-8 h-8 self-center md:w-7 md:h-7 rotate-180 md:m-1 text-blueRus-100"
      />
      <p
        class="w-[95%] md:w-[36rem] font-montserrat text-[10px] md:text-sm leading-4 md:leading-6"
      >
        En este portal vas a poder gestionar los servicios adicionales a través
        de <b>Rapihogar®</b>. Por consultas sobre dichas asistencias podrás
        contactarte por WhatsApp al
        <a :href="whatsappCall" target="_blank" rel="noopener noreferrer">
          <!-- <b class="text-link">+54-11-20401417</b> -->
          <b class="text-link">{{ clientStore.whatsappNumber }}</b>
        </a>

        y por teléfono al
        <a :href="phoneCall" target="_blank" rel="noopener noreferrer">
          <!-- <b class="text-link">+54-08108887080</b>  -->
          <b class="text-link">{{ clientStore.phoneNumber }}</b> </a
        >.
      </p>
      <XIcon
        @click="$emit('closeMessage')"
        class="w-6 h-6 md:w-7 md:h-7 transition-all rounded-full p-1 hover:bg-gray-200 duration-300 cursor-pointer text-[#D23232]"
      />
    </div>
  </Transition>
</template>

<style scoped>
.container {
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.message-enter-from {
  opacity: 0;
}

.message-leave-to {
  opacity: 0;
}

.message-enter-from .container,
.message-leave-to .container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
