<script setup lang="ts">
import { useField } from "vee-validate";
import { toRef } from "vue";

const props = defineProps({
  value: { type: String, default: "" },
  name: { type: String, required: true },
  label: { type: String, required: true },
  placeholder: {
    type: String,
    default: "",
  },
  rows: { type: String, default: "8" },
});

const name = toRef(props, "name");

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField<string>(name, undefined, {
  initialValue: props.value,
});
</script>

<template>
  <div>
    <label class="title text-sm 2xl:text-base">{{ label }}</label>
    <textarea
      :placeholder="placeholder"
      style="resize: none"
      class="base-input w-full pr-3 text-sm 2xl:text-base"
      :rows="rows"
      :resize="false"
      @input="handleChange"
      @blur="handleBlur"
      :value="inputValue"
      :id="name"
    ></textarea>
    <p
      class="text-[#D23232] text-xs md:text-sm 2xl:text-base font-medium font-montserrat"
      v-if="errorMessage && meta.touched"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
