<template>
  <div :class="clientTheme" class="flex justify-end gap-x-2 md:gap-x-6">
    <button
      class="btn btn-transition flex justify-center items-center border-[1.5px] border-grayDetails-100 hover:border-skin-major w-24 md:w-28 2xl:w-32 h-9 md:h-10 2xl:h-11 text-grayDetails-200 hover:text-skin-major font-bold"
      :disabled="loading"
      type="button"
      v-if="hasPrevious"
      @click="$emit('click')"
    >
      Volver
    </button>
    <button
      class="btn btn-salmon btn-transition flex relative justify-center items-center border-[1.5px] border-grayDetails-100 w-36 md:w-48 2xl:w-52 h-9 md:h-10 2xl:h-11 font-bold bg-skin-major hover:bg-skin-button-bg-hover"
      :disabled="loading"
      type="submit"
      :class="[
        isLastStep || isRefund
          ? 'hover:bg-skin-button-bg-hover hover:text-white btn-transition'
          : '',
        {
          'bg-skin-major': loading,
        },
      ]"
    >
      <svg
        v-show="loading"
        class="w-5 h-5 text-white animate-spin absolute left-1/2 -ml-2.5"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        ></path>
      </svg>
      <span v-show="!loading" class="font-bold">
        {{ isLastStep || isRefund ? "Enviar solicitud" : "Continuar" }}
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, type Ref } from "vue";
import { useClientStore } from "@/stores/clientStore";

defineProps<{
  hasPrevious: boolean;
  isLastStep: boolean;
  isRefund: boolean;
}>();

defineEmits<{
  (e: "click"): void;
}>();

const clientStore = useClientStore();

const clientTheme = clientStore.theme;

const loading = inject<Ref<boolean>>("LOADING", ref(false));
</script>

<style lang="scss" scoped></style>
