import { defineStore } from "pinia";
import {
  fetchNotifications,
  markAsRead,
} from "@/services/notification.services";

import type { Request } from "@/interfaces/product.interface";
import type { MessagePayload } from "firebase/messaging";

type NotificationStatus = "En Curso" | "Cancelada" | "Realizada";

export interface Notification {
  id: number;
  created_time: string;
  title: string;
  body: string;
  request: Request;
  request_state: NotificationStatus;
  read: boolean;
}

export const useNotificationStore = defineStore("notification", {
  state: () => ({
    notifications: [] as Notification[],
    newNotification: {} as MessagePayload,
    showNotification: false,
  }),

  actions: {
    async getNotifications(): Promise<void> {
      try {
        const { data } = await fetchNotifications();
        this.notifications = data;
      } catch (error) {
        throw new Error("Error loading notifications");
      }
    },

    async markNotificationAsRead(notification: Notification): Promise<void> {
      if (notification.read) return;

      try {
        try {
          await markAsRead(notification.id);
        } catch (error) {
          throw new Error("Error when trying to mark as read");
        }
      } catch (error) {
        throw new Error("Error seting the request notification");
      }
    },

    closeNotificationAlert() {
      this.showNotification = false;
      this.newNotification = {} as MessagePayload;
    },
  },
});
