<script setup lang="ts">
import { ref } from "vue";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";

const emit = defineEmits(["verify"]);

// Reset Recaptcha
const { resetRecaptcha } = useRecaptcha();
const recaptchaWidget = ref();

const callbackVerify = (event: Event): void => {
  emit("verify", event);
};

const callbackExpired = () => {
  console.log("expired!");
};

const callbackFail = () => {
  console.log("fail");
};

// Reset Recaptcha action
const actionReset = () => {
  resetRecaptcha(recaptchaWidget.value);
};
</script>

<template>
  <vue-recaptcha
    class="block scale-95 md:scale-75 lg:scale-90"
    theme="light"
    size="normal"
    :tabindex="0"
    @widgetId="recaptchaWidget = $event"
    @verify="callbackVerify($event)"
    @expired="callbackExpired()"
    @fail="callbackFail()"
  />

  <button
    class="text-left text-blue-500 underline hover:text-blue-900 hidden md:block"
    @click.prevent="actionReset()"
  >
    recargar reCAPTCHA
  </button>
</template>

<style scoped></style>
