<template>
  <svg
    width="276"
    height="80"
    viewBox="0 0 276 80"
    :fill="svgColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M185.964 64.1275C180.32 64.1275 173.168 62.4274 169.033 58.4444C164.897 54.4614 163 48.7784 163 43.0953V14.0486H178.131C178.131 23.7146 178.131 33.4293 178.131 43.0953C178.131 45.7668 178.763 48.7298 180.904 50.5756C183.044 52.3728 186.401 52.6642 188.98 51.6928C192.921 50.2356 193.748 46.0583 193.748 42.3182C193.748 36.4408 193.748 30.5149 193.748 24.6375C193.748 21.0917 193.748 17.5458 193.748 14H209.511V43.0953C209.511 48.6327 207.662 54.2186 203.721 58.2016C199.586 62.3789 191.704 64.1275 185.964 64.1275Z"
      :fill="firstColor"
    />
    <path
      d="M236.008 64.4566C227.932 64.4566 220.051 61.7851 214.407 57.0735L214.164 56.8792L218.932 50.6618C219.905 49.3503 221.364 48.6703 223.164 48.6703C224.527 48.6703 226.035 49.0589 227.446 49.7875C230.316 51.2447 232.895 52.4104 236.008 52.5076H236.398C239.803 52.5076 241.603 51.4875 241.847 49.496C242.187 46.6302 238.636 45.8045 236.057 45.173L234.695 44.833C228.711 43.4244 221.948 41.8215 218.689 36.2841C215.526 30.9411 216.694 23.898 221.413 19.4778C226.424 14.7662 233.381 13.7948 238.344 13.7948C238.879 13.7948 239.463 13.7948 239.998 13.8433C246.858 14.1348 252.793 16.1749 257.658 19.915L257.902 20.0607L253.864 26.2295C252.696 27.7838 251.236 28.561 249.388 28.561C248.22 28.561 246.906 28.221 245.447 27.5895C243.258 26.6181 240.825 25.6952 238.052 25.6952C237.614 25.6952 237.176 25.6952 236.738 25.7438C235.814 25.8409 234.549 26.0838 233.722 26.9095C233.284 27.3953 232.992 28.0753 233.041 28.7067C233.041 29.0467 233.138 29.4839 233.527 29.8239C235.23 31.2811 237.809 31.864 240.29 32.4469C241.068 32.6412 241.798 32.7869 242.479 32.9812C249.242 34.827 255.761 37.4499 257.561 43.7158C259.264 49.5932 256.685 56.3449 251.334 60.1821C246.42 63.728 240.29 64.4566 236.008 64.4566Z"
      :fill="firstColor"
    />
    <path
      d="M268.897 14.3291C265.443 14.3291 262.669 17.1463 262.669 20.6436C262.669 24.0923 265.443 26.9581 268.897 26.9581C272.351 26.9581 275.222 24.1409 275.222 20.6436C275.222 17.1463 272.4 14.3291 268.897 14.3291ZM268.897 25.9381C266.026 25.9381 263.643 23.5094 263.643 20.595C263.643 17.6806 266.026 15.252 268.897 15.252C271.816 15.252 274.249 17.632 274.249 20.595C274.249 23.558 271.865 25.9381 268.897 25.9381Z"
      :fill="firstColor"
    />
    <path
      d="M266.172 17.3892H269.189C270.016 17.3892 270.697 17.632 271.135 18.0692C271.524 18.4578 271.719 18.9435 271.719 19.575C271.719 20.1093 271.573 20.5464 271.329 20.8864C271.086 21.2265 270.746 21.4693 270.308 21.615L271.913 23.9951H270.21L268.8 21.8579H267.632V23.9951H266.172V17.3892ZM269.091 20.595C269.432 20.595 269.724 20.4979 269.919 20.3521C270.113 20.2064 270.21 19.9636 270.21 19.6721C270.21 19.3807 270.113 19.1378 269.919 18.9435C269.724 18.7978 269.432 18.7007 269.091 18.7007H267.632V20.595H269.091Z"
      :fill="firstColor"
    />
    <path
      d="M149.944 45.3673C152.231 44.1044 153.787 42.5986 155.198 40.6557C157.047 38.1299 157.971 34.9241 157.971 31.1354V30.9897C157.971 26.2781 156.512 22.3922 153.544 19.4778C151.89 17.8263 149.749 16.5149 147.122 15.592C144.544 14.6691 141.333 14.1833 137.635 14.1833H114.526V62.8537H129.17V48.5732H133.743C134.327 48.5732 134.862 48.5731 135.397 48.5246V48.5732C135.397 48.5732 139.435 54.5962 142.354 59.0649C144.836 62.8537 149.944 62.8537 153.447 62.8537C156.95 62.8537 161.864 62.8537 161.864 62.8537C161.864 62.8537 153.155 50.1275 149.944 45.3673ZM129.17 36.8184V27.0067H137.003C138.997 27.0067 140.554 27.4438 141.673 28.2696C142.744 29.0467 143.279 30.2611 143.279 31.864V32.0097C143.279 33.4669 142.744 34.6327 141.722 35.4584C140.651 36.3327 139.046 36.8184 137.051 36.8184H129.17Z"
      :fill="firstColor"
    />
    <path
      d="M115.109 72.2769H118.272C118.71 72.2769 119.148 72.3255 119.488 72.4712C119.829 72.6169 120.121 72.7626 120.364 73.0055C120.558 73.1998 120.704 73.3941 120.802 73.6369C120.899 73.8798 120.948 74.1712 120.948 74.4627C120.948 74.7541 120.899 75.0456 120.802 75.2884C120.704 75.5313 120.558 75.7256 120.364 75.9199C120.169 76.1142 119.975 76.2599 119.731 76.357C119.488 76.4542 119.196 76.5513 118.904 76.5999L121.191 79.6114H120.169L118.029 76.7456H115.937V79.6114H115.109V72.2769ZM118.223 76.0656C118.515 76.0656 118.758 76.017 119.002 75.9684C119.245 75.9199 119.439 75.7742 119.585 75.677C119.731 75.5313 119.877 75.3856 119.975 75.1913C120.072 74.997 120.121 74.8027 120.121 74.5598C120.121 74.1227 119.975 73.7341 119.634 73.4912C119.293 73.2483 118.856 73.1026 118.223 73.1026H115.937V76.1142H118.223V76.0656Z"
      fill="secondColor"
    />
    <path
      d="M123.38 72.2769H124.207V79.66H123.38V72.2769Z"
      :fill="secondColor"
    />
    <path
      d="M130.337 79.8057C129.754 79.8057 129.267 79.7086 128.781 79.5143C128.294 79.32 127.905 79.0285 127.613 78.6885C127.272 78.3485 127.029 77.9114 126.834 77.4742C126.64 76.9885 126.543 76.5513 126.543 76.017C126.543 75.5313 126.64 75.0455 126.834 74.5598C127.029 74.0741 127.272 73.6855 127.613 73.3455C127.953 73.0055 128.343 72.714 128.829 72.5197C129.316 72.3255 129.802 72.2283 130.386 72.2283C130.97 72.2283 131.456 72.3255 131.943 72.5197C132.429 72.714 132.819 73.0055 133.111 73.3455C133.451 73.6855 133.694 74.1227 133.889 74.5598C134.084 75.0455 134.181 75.4827 134.181 76.017C134.181 76.5027 134.084 76.9885 133.889 77.4742C133.694 77.9599 133.451 78.3485 133.111 78.6885C132.77 79.0285 132.381 79.32 131.894 79.5143C131.408 79.7086 130.921 79.8057 130.337 79.8057ZM130.337 79.0285C130.775 79.0285 131.164 78.9314 131.505 78.7857C131.846 78.64 132.186 78.3971 132.429 78.1542C132.673 77.8628 132.867 77.5713 133.013 77.1828C133.159 76.7942 133.208 76.4056 133.208 76.017C133.208 75.5799 133.159 75.1913 133.013 74.8513C132.867 74.4627 132.673 74.1712 132.381 73.8798C132.137 73.5884 131.797 73.3941 131.456 73.2483C131.116 73.1026 130.727 73.0055 130.289 73.0055C129.851 73.0055 129.462 73.1026 129.121 73.2483C128.781 73.3941 128.44 73.6369 128.197 73.8798C127.953 74.1712 127.759 74.4627 127.613 74.8513C127.467 75.2398 127.418 75.6284 127.418 76.017C127.418 76.4542 127.467 76.8428 127.613 77.1828C127.759 77.5713 127.953 77.8628 128.245 78.1542C128.537 78.4457 128.829 78.64 129.17 78.8343C129.559 78.98 129.948 79.0285 130.337 79.0285Z"
      :fill="secondColor"
    />
    <path
      d="M143.084 79.8057C142.646 79.8057 142.208 79.7572 141.819 79.6114C141.43 79.4657 141.138 79.2714 140.846 78.98C140.554 78.6886 140.36 78.3485 140.214 77.96C140.068 77.5714 139.97 77.0856 139.97 76.5513V72.2283H140.797V76.5028C140.797 77.3285 140.992 77.9114 141.381 78.3485C141.771 78.7857 142.354 78.98 143.035 78.98C143.717 78.98 144.252 78.7857 144.69 78.3485C145.079 77.96 145.273 77.3285 145.273 76.5028V72.1797H146.1V76.4056C146.1 76.9399 146.003 77.4256 145.857 77.8142C145.711 78.2028 145.517 78.5428 145.225 78.8343C144.933 79.1257 144.641 79.32 144.252 79.4657C144.008 79.7572 143.571 79.8057 143.084 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M148.728 72.2769H151.89C152.328 72.2769 152.766 72.3255 153.106 72.4712C153.447 72.6169 153.739 72.7626 153.982 73.0055C154.177 73.1998 154.323 73.3941 154.42 73.6369C154.517 73.8798 154.566 74.1712 154.566 74.4627C154.566 74.7541 154.517 75.0456 154.42 75.2884C154.323 75.5313 154.177 75.7256 153.982 75.9199C153.787 76.1142 153.593 76.2599 153.35 76.357C153.106 76.4542 152.814 76.5513 152.522 76.5999L154.809 79.6114H153.787L151.647 76.7456H149.555V79.6114H148.728V72.2769ZM151.841 76.0656C152.133 76.0656 152.377 76.017 152.62 75.9684C152.863 75.9199 153.058 75.7742 153.204 75.677C153.35 75.5313 153.496 75.3856 153.593 75.1913C153.69 74.997 153.739 74.8027 153.739 74.5598C153.739 74.1227 153.593 73.7341 153.252 73.4912C152.912 73.2483 152.474 73.1026 151.841 73.1026H149.555V76.1142H151.841V76.0656Z"
      :fill="secondColor"
    />
    <path
      d="M159.869 79.8057C159.431 79.8057 158.993 79.7572 158.604 79.6114C158.215 79.4657 157.923 79.2714 157.631 78.98C157.339 78.6886 157.144 78.3485 156.998 77.96C156.852 77.5714 156.755 77.0856 156.755 76.5513V72.2283H157.582V76.5028C157.582 77.3285 157.777 77.9114 158.166 78.3485C158.555 78.7857 159.139 78.98 159.82 78.98C160.501 78.98 161.036 78.7857 161.474 78.3485C161.912 77.9114 162.058 77.3285 162.058 76.5028V72.1797H162.885V76.4056C162.885 76.9399 162.788 77.4256 162.642 77.8142C162.496 78.2028 162.301 78.5428 162.01 78.8343C161.718 79.1257 161.426 79.32 161.036 79.4657C160.745 79.7572 160.307 79.8057 159.869 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M168.918 79.8057C168.334 79.8057 167.799 79.7086 167.361 79.5143C166.875 79.32 166.485 79.0286 166.194 78.6885C165.853 78.3485 165.61 77.96 165.464 77.4742C165.269 76.9885 165.221 76.5028 165.221 75.9684C165.221 75.4827 165.318 74.997 165.512 74.5113C165.707 74.0255 165.95 73.6369 166.291 73.2969C166.631 72.9569 167.021 72.6655 167.458 72.4712C167.896 72.2769 168.432 72.1797 168.967 72.1797C169.259 72.1797 169.551 72.1797 169.842 72.2283C170.086 72.2769 170.329 72.3255 170.572 72.4226C170.815 72.5198 171.01 72.6169 171.205 72.714C171.399 72.8598 171.594 72.9569 171.788 73.1512L171.253 73.7827C171.107 73.6369 170.961 73.5398 170.815 73.4426C170.669 73.3455 170.475 73.2484 170.329 73.1998C170.134 73.1026 169.94 73.0541 169.745 73.0055C169.55 72.9569 169.307 72.9569 169.015 72.9569C168.626 72.9569 168.237 73.0541 167.896 73.1998C167.556 73.3455 167.264 73.5884 167.021 73.8312C166.777 74.1227 166.583 74.4141 166.437 74.8027C166.291 75.1913 166.242 75.5799 166.242 75.9684C166.242 76.4056 166.291 76.8428 166.437 77.1828C166.583 77.5714 166.777 77.8628 167.021 78.1542C167.264 78.4457 167.604 78.64 167.945 78.7857C168.286 78.9314 168.723 79.0286 169.161 79.0286C169.599 79.0286 169.988 78.98 170.378 78.8343C170.767 78.6886 171.059 78.4943 171.302 78.3V76.4542H168.821V75.7256H171.886V78.6885C171.545 78.98 171.107 79.2714 170.621 79.4657C170.086 79.7086 169.551 79.8057 168.918 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M177.286 79.8057C176.848 79.8057 176.41 79.7572 176.021 79.6114C175.632 79.4657 175.34 79.2714 175.048 78.98C174.756 78.6886 174.562 78.3485 174.416 77.96C174.27 77.5714 174.172 77.0856 174.172 76.5513V72.2283H174.999V76.5028C174.999 77.3285 175.194 77.9114 175.583 78.3485C175.972 78.7857 176.556 78.98 177.237 78.98C177.919 78.98 178.454 78.7857 178.892 78.3485C179.329 77.9114 179.475 77.3285 179.475 76.5028V72.1797H180.302V76.4056C180.302 76.9399 180.205 77.4256 180.059 77.8142C179.913 78.2028 179.719 78.5428 179.427 78.8343C179.135 79.1257 178.843 79.32 178.454 79.4657C178.162 79.7572 177.724 79.8057 177.286 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M185.606 72.2283H186.384L189.741 79.7086H188.817L187.941 77.7656H183.903L183.027 79.7086H182.151L185.606 72.2283ZM187.698 76.9885L185.995 73.1998L184.292 76.9885H187.698Z"
      :fill="secondColor"
    />
    <path
      d="M194.071 76.7456L191.006 72.2769H192.027L194.509 75.9684L197.039 72.2769H198.012L194.947 76.7456V79.7086H194.119V76.7456H194.071Z"
      :fill="secondColor"
    />
    <path
      d="M205.504 79.8057C204.92 79.8057 204.385 79.7086 203.898 79.5143C203.412 79.32 202.925 79.0285 202.488 78.64L203.023 78.0085C203.412 78.3485 203.801 78.64 204.19 78.7857C204.58 78.98 205.017 79.0285 205.553 79.0285C206.039 79.0285 206.477 78.9314 206.769 78.6885C207.061 78.4457 207.207 78.1542 207.207 77.7656C207.207 77.6199 207.158 77.4256 207.109 77.3285C207.061 77.1828 206.964 77.0856 206.818 76.9399C206.672 76.8428 206.477 76.7456 206.234 76.6485C205.99 76.5513 205.65 76.4542 205.261 76.4056C204.823 76.3085 204.482 76.2113 204.142 76.0656C203.85 75.9684 203.558 75.8227 203.363 75.6284C203.169 75.4827 203.023 75.2398 202.925 75.0455C202.828 74.8027 202.779 74.5598 202.779 74.2684C202.779 73.9769 202.828 73.7341 202.974 73.4426C203.071 73.1998 203.266 73.0055 203.461 72.8112C203.704 72.6169 203.947 72.4712 204.239 72.374C204.531 72.2769 204.871 72.2283 205.212 72.2283C205.747 72.2283 206.234 72.3254 206.623 72.4712C207.012 72.6169 207.401 72.8598 207.791 73.1512L207.304 73.7826C206.964 73.4912 206.623 73.2969 206.282 73.1512C205.942 73.0055 205.553 72.9569 205.163 72.9569C204.677 72.9569 204.288 73.054 203.996 73.2969C203.704 73.5398 203.558 73.8312 203.558 74.1712C203.558 74.3655 203.607 74.5112 203.655 74.657C203.704 74.8027 203.801 74.8998 203.947 75.0455C204.093 75.1427 204.288 75.2884 204.58 75.3856C204.823 75.4827 205.163 75.5799 205.601 75.677C206.428 75.8713 207.012 76.1142 207.401 76.4542C207.791 76.7942 207.985 77.2313 207.985 77.7656C207.985 78.1057 207.937 78.3485 207.791 78.64C207.645 78.8828 207.499 79.1257 207.255 79.32C207.012 79.5143 206.769 79.66 206.477 79.7571C206.185 79.7571 205.845 79.8057 205.504 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M210.272 72.2769H215.623V73.0541H211.099V75.5799H215.137V76.357H211.099V78.9314H215.672V79.7086H210.272V72.2769Z"
      :fill="secondColor"
    />
    <path
      d="M221.316 79.8057C220.732 79.8057 220.197 79.7086 219.759 79.5143C219.272 79.32 218.883 79.0286 218.591 78.6885C218.251 78.3485 218.007 77.96 217.861 77.4742C217.667 76.9885 217.618 76.5028 217.618 75.9684C217.618 75.4827 217.716 74.997 217.91 74.5113C218.105 74.0255 218.348 73.6369 218.689 73.2969C219.029 72.9569 219.418 72.6655 219.856 72.4712C220.294 72.2769 220.829 72.1797 221.364 72.1797C221.656 72.1797 221.948 72.1797 222.24 72.2283C222.483 72.2769 222.727 72.3255 222.97 72.4226C223.213 72.5198 223.408 72.6169 223.602 72.714C223.797 72.8598 223.992 72.9569 224.186 73.1512L223.651 73.7827C223.505 73.6369 223.359 73.5398 223.213 73.4426C223.067 73.3455 222.873 73.2484 222.727 73.1998C222.532 73.1026 222.337 73.0541 222.143 73.0055C221.948 72.9569 221.705 72.9569 221.413 72.9569C221.024 72.9569 220.635 73.0541 220.294 73.1998C219.953 73.3455 219.662 73.5884 219.418 73.8312C219.175 74.1227 218.98 74.4141 218.834 74.8027C218.689 75.1913 218.64 75.5799 218.64 75.9684C218.64 76.4056 218.689 76.8428 218.834 77.1828C218.98 77.5714 219.175 77.8628 219.418 78.1542C219.662 78.4457 220.002 78.64 220.343 78.7857C220.683 78.9314 221.121 79.0286 221.559 79.0286C221.997 79.0286 222.386 78.98 222.775 78.8343C223.164 78.6886 223.456 78.4943 223.7 78.3V76.4542H221.462V75.7256H224.527V78.6885C224.186 78.98 223.748 79.2714 223.262 79.4657C222.532 79.7086 221.948 79.8057 221.316 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M229.684 79.8057C229.246 79.8057 228.808 79.7572 228.419 79.6114C228.03 79.4657 227.738 79.2714 227.446 78.98C227.154 78.6886 226.959 78.3485 226.813 77.96C226.667 77.5714 226.57 77.0856 226.57 76.5513V72.2283H227.397V76.5028C227.397 77.3285 227.592 77.9114 227.981 78.3485C228.37 78.7857 228.954 78.98 229.635 78.98C230.316 78.98 230.851 78.7857 231.289 78.3485C231.727 77.9114 231.873 77.3285 231.873 76.5028V72.1797H232.7V76.4056C232.7 76.9399 232.603 77.4256 232.457 77.8142C232.311 78.2028 232.116 78.5428 231.824 78.8343C231.533 79.1257 231.241 79.32 230.851 79.4657C230.559 79.7572 230.17 79.8057 229.684 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M235.279 72.2769H238.441C238.879 72.2769 239.317 72.3255 239.657 72.4712C239.998 72.6169 240.29 72.7626 240.533 73.0055C240.728 73.1998 240.874 73.3941 240.971 73.6369C241.068 73.8798 241.117 74.1712 241.117 74.4627C241.117 74.7541 241.068 75.0456 240.971 75.2884C240.874 75.5313 240.728 75.7256 240.533 75.9199C240.338 76.1142 240.144 76.2599 239.901 76.357C239.657 76.4542 239.365 76.5513 239.073 76.5999L241.36 79.6114H240.338L238.198 76.7456H236.106V79.6114H235.279V72.2769ZM238.392 76.0656C238.684 76.0656 238.928 76.017 239.171 75.9684C239.414 75.9199 239.609 75.7742 239.755 75.677C239.901 75.5313 240.047 75.3856 240.144 75.1913C240.241 74.997 240.29 74.8027 240.29 74.5598C240.29 74.1227 240.144 73.7341 239.803 73.4912C239.463 73.2483 239.025 73.1026 238.392 73.1026H236.106V76.1142H238.392V76.0656Z"
      :fill="secondColor"
    />
    <path
      d="M246.906 79.8057C246.323 79.8057 245.836 79.7086 245.35 79.5143C244.863 79.32 244.474 79.0285 244.182 78.6885C243.841 78.3485 243.598 77.9114 243.403 77.4742C243.209 76.9885 243.112 76.5513 243.112 76.017C243.112 75.5313 243.209 75.0455 243.403 74.5598C243.598 74.0741 243.841 73.6855 244.182 73.3455C244.522 73.0055 244.912 72.714 245.398 72.5197C245.885 72.3255 246.371 72.2283 246.955 72.2283C247.539 72.2283 248.025 72.3255 248.512 72.5197C248.998 72.714 249.388 73.0055 249.68 73.3455C250.02 73.6855 250.263 74.1227 250.458 74.5598C250.653 75.0455 250.75 75.4827 250.75 76.017C250.75 76.5027 250.653 76.9885 250.458 77.4742C250.263 77.9599 250.02 78.3485 249.68 78.6885C249.339 79.0285 248.95 79.32 248.463 79.5143C248.025 79.7086 247.49 79.8057 246.906 79.8057ZM246.955 79.0285C247.393 79.0285 247.782 78.9314 248.123 78.7857C248.463 78.64 248.804 78.3971 249.047 78.1542C249.29 77.8628 249.485 77.5713 249.631 77.1828C249.777 76.7942 249.825 76.4056 249.825 76.017C249.825 75.5799 249.777 75.1913 249.631 74.8513C249.485 74.4627 249.29 74.1712 248.998 73.8798C248.755 73.5884 248.415 73.3941 248.074 73.2483C247.733 73.1026 247.344 73.0055 246.906 73.0055C246.469 73.0055 246.079 73.1026 245.739 73.2483C245.398 73.3941 245.058 73.6369 244.814 73.8798C244.571 74.1227 244.377 74.4627 244.231 74.8513C244.085 75.2398 244.036 75.6284 244.036 76.017C244.036 76.4542 244.085 76.8428 244.231 77.1828C244.377 77.5713 244.571 77.8628 244.863 78.1542C245.106 78.4457 245.447 78.64 245.787 78.8343C246.128 78.98 246.517 79.0285 246.955 79.0285Z"
      :fill="secondColor"
    />
    <path
      d="M255.518 79.8057C254.934 79.8057 254.399 79.7086 253.912 79.5143C253.426 79.32 252.939 79.0285 252.501 78.64L253.036 78.0085C253.426 78.3485 253.815 78.64 254.204 78.7857C254.593 78.98 255.031 79.0285 255.566 79.0285C256.053 79.0285 256.491 78.9314 256.783 78.6885C257.075 78.4457 257.221 78.1542 257.221 77.7656C257.221 77.6199 257.172 77.4256 257.123 77.3285C257.075 77.1828 256.977 77.0856 256.831 76.9399C256.685 76.8428 256.491 76.7456 256.247 76.6485C256.004 76.5513 255.664 76.4542 255.274 76.4056C254.837 76.3085 254.496 76.2113 254.155 76.0656C253.864 75.9684 253.572 75.8227 253.377 75.6284C253.182 75.4827 253.036 75.2398 252.939 75.0455C252.842 74.8027 252.793 74.5598 252.793 74.2684C252.793 73.9769 252.842 73.7341 252.988 73.4426C253.085 73.1998 253.28 73.0055 253.474 72.8112C253.718 72.6169 253.961 72.4712 254.253 72.374C254.545 72.2769 254.885 72.2283 255.226 72.2283C255.761 72.2283 256.247 72.3254 256.637 72.4712C257.026 72.6169 257.415 72.8598 257.804 73.1512L257.318 73.7826C256.977 73.4912 256.637 73.2969 256.296 73.1512C255.956 73.0055 255.566 72.9569 255.177 72.9569C254.691 72.9569 254.301 73.054 254.009 73.2969C253.718 73.5398 253.572 73.8312 253.572 74.1712C253.572 74.3655 253.62 74.5112 253.669 74.657C253.718 74.8027 253.815 74.8998 253.961 75.0455C254.107 75.1427 254.301 75.2884 254.593 75.3856C254.837 75.4827 255.177 75.5799 255.615 75.677C256.442 75.8713 257.026 76.1142 257.415 76.4542C257.804 76.7942 257.999 77.2313 257.999 77.7656C257.999 78.1057 257.95 78.3485 257.804 78.64C257.658 78.8828 257.512 79.1257 257.269 79.32C257.026 79.5143 256.783 79.66 256.491 79.7571C256.199 79.7571 255.858 79.8057 255.518 79.8057Z"
      :fill="secondColor"
    />
    <path
      d="M81.9291 13.1633C78.8154 15.252 77.1613 17.5835 76.2369 19.7207C75.6531 18.5064 74.5341 17.1949 73.0746 16.1263C68.2581 12.5804 59.5008 9.90892 53.9545 5.87735C53.9545 5.87735 54.1978 13.0176 59.4035 16.8063C64.0254 20.2064 69.1338 20.8379 71.5664 22.4408C73.9503 23.9951 74.5341 25.7923 75.0206 26.7638V26.8124C75.5071 25.5495 78.6695 21.0807 82.9022 19.3807C88.4484 17.0978 91.8054 17.2435 98.1301 13.6976C105.866 9.32605 108.055 0.19429 108.055 0.19429C99.2491 7.09168 88.935 8.45173 81.9291 13.1633Z"
      :fill="firstColor"
    />
    <path
      d="M5.98414 55.1305L0.778427 64.2623C14.5955 56.9763 28.9476 56.5392 45.4405 62.4165C67.6256 70.2854 77.1126 67.5653 81.4426 60.1336C84.4104 54.8391 94.3353 37.7413 94.3353 37.7413C69.8636 57.3163 54.0518 39.6843 25.3474 44.0073C17.6605 45.1245 9.34109 49.3018 5.98414 55.1305Z"
      :fill="thirdColor"
    />
    <path
      d="M73.7557 46.9702C74.0476 46.9217 74.3395 46.9217 74.6314 46.8731C80.8102 46.0473 87.2321 43.473 94.3353 37.7899L102.022 24.2866C75.945 43.813 52.495 25.5495 33.9588 27.541C25.1042 28.5124 19.9958 31.0868 16.6388 37.0127C13.2332 42.9872 12.6007 43.8616 12.6007 43.8616C16.9307 41.4329 23.3527 40.0243 29.6288 39.6843C44.8567 38.8099 59.4035 47.2131 73.7557 46.9702Z"
      :fill="firstColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    svgColor?: string;
  }>(),
  { svgColor: "none" }
);
const firstColor = computed(() => {
  return props.svgColor ? props.svgColor : "#001BDA";
});
const secondColor = computed(() => {
  return props.svgColor ? props.svgColor : "#3E4B59";
});
const thirdColor = computed(() => {
  return props.svgColor ? props.svgColor : "#0074FF";
});
</script>
