<script setup lang="ts">
import { useFormComponent } from "@/composables/SetupFormComponent";
import { useIcon } from "@/composables/icons";
import { useUniqueID } from "@/composables/uniqueID";

interface Props {
  label: string;
  modelValue: string | number;
  value: string | number;
  error?: string;
  icon?: string;
  subLabel?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  modelValue: "",
  error: "",
});

const { updateValue } = useFormComponent(props);

const uuid = useUniqueID().getID();
</script>

<template>
  <input
    type="radio"
    class="base-radio"
    v-bind="{ ...$attrs, onChange: updateValue }"
    :checked="props.modelValue === props.value"
    :id="uuid"
  />
  <div class="ml-2 mr-2 md:mr-4" v-if="props.icon">
    <component
      :is="useIcon(props.icon)"
      :class="$attrs.class"
      class="md:pt-1 w-6 h-6 text-skin-major group-hover:text-skin-highlight group-focus:text-skin-highlight"
      data-test="icon"
    />
  </div>
  <div v-if="props.icon">
    <label
      class="font-montserrat text-grayDetails-400 text-sm md:text-base font-semibold"
      v-if="props.label"
      :for="uuid"
      >{{ props.label }}</label
    >
    <p
      v-if="props.subLabel"
      class="text-xs md:text-sm font-medium"
      style="color: #78909c"
    >
      {{ props.subLabel }}
    </p>
  </div>
  <template v-else>
    <label
      class="text-xs 2xl:text-sm font-montserrat font-medium text-grayDetails-400"
      v-if="props.label"
      :for="uuid"
      >{{ props.label }}</label
    >
  </template>
  <BaseErrorMessage
    class="font-medium text-[15px] 2xl:text-base font-montserrat"
    v-if="props.error"
    :id="`${uuid}-error`"
  >
    {{ props.error }}
  </BaseErrorMessage>
</template>

<style scoped>
input:checked + label {
  color: blue;
  font-weight: bold;
}
</style>
