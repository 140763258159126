<script setup lang="ts">
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { ref } from "vue";

const placeholderSpace = ref(true);

const emits = defineEmits<{
  (e: "search", event: Event): void;
  (e: "search-status", event: Event): void;
}>();

const status = [
  { id: 1, name: "En curso" },
  { id: 2, name: "Realizado" },
  { id: 3, name: "Cancelado" },
];

function searchByStatus(evt: Event) {
  emits("search-status", evt);
}

function search(evt: Event) {
  emits("search", evt);
}
</script>

<template>
  <div class="relative group flex justify-end">
    <select
      @change="searchByStatus($event)"
      class="bg-white border-grayDetails-100 text-grayDetails-100 rounded-md shadow-sm cursor-pointer md:py-[10px] font-montserrat text-xs"
    >
      <option value="">Todos</option>
      <option
        class="text-grayDetails-400"
        :class="[
          item.id === 1 ? 'text-yellowStatus font-semibold' : '',
          item.id === 2 ? 'text-greenStatus-200 font-semibold' : '',
          item.id === 3 ? 'text-danger font-semibold' : '',
        ]"
        v-for="item of status"
        :key="item.id"
      >
        {{ item.name }}
      </option>
    </select>
    <div class="absolute inset-y-0 flex items-center pointer-events-none">
      <ChevronRightIcon
        class="h-[24px] w-[24px] mx-3 text-grayDetails-100 group-active:rotate-90 duration-300"
      />
    </div>
  </div>
  <div class="flex-grow md:pt-3">
    <div class="relative mt-0 md:mt-[10px]">
      <BaseInput
        @keyup="search($event)"
        type="text"
        placeholder="Buscar"
        :placeholderSpace="placeholderSpace"
        class="md:pr-3 md:pl-11 py-[10px] w-full bg-white border border-grayDetails-100 rounded-md cursor-pointer placeholder-grayDetails-100 font-montserrat text-xs md:text-[15px]"
      />
      <div
        class="absolute inset-y-0 letf-[-5px] md:left-11 flex items-center pointer-events-none"
      >
        <SearchIcon class="h-5 md:h-6 w-5 md:w-6 mx-3 text-grayDetails-100" />
      </div>
    </div>
  </div>
</template>

<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
}
</style>
