<template>
  <component
    class="w-full text-center"
    v-for="(option, idx) of options"
    :key="idx"
    :is="'div'"
  >
    <CustomButton
      :modelValue="modelValue"
      :value="itemValue ? option[itemValue] : option"
      :label="itemText ? option[itemText] : option"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
    <p v-if="showDescription" class="text-primary text-grayDetails-400 text-xs">
      {{ verifyAndReturnDescription(option) }}
    </p>
  </component>
</template>

<script lang="ts" setup>
import { toRefs } from "vue";
import CustomButton from "./CustomButton.vue";

const props = defineProps({
  options: { type: [Array, Object], required: true },
  showDescription: { type: Boolean, default: false },
  modelValue: { type: [String, Number, Object], default: null },
  itemText: { type: [String, Number], default: "" },
  itemValue: { type: [String, Number], default: "" },
  itemDescription: { type: [String, Number], default: "" },
});

const { itemDescription, itemText } = toRefs(props);

function verifyAndReturnDescription(option: Record<string, string>) {
  if (option === undefined || option === null) return "";
  if (option[itemDescription.value] === option[itemText.value]) return "";
  return option[itemDescription.value];
}
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.button {
  width: 100%;
  max-width: 200px;
  padding: 1em 2em;
  background: blanchedalmond;
  font-size: medium;
  text-align: center;
  cursor: pointer;
}

.bg-red {
  background: red;
  border: 2px solid blue;
}
</style>
