<script setup lang="ts">
import { computed } from "vue";
import { FieldArray } from "vee-validate";

import { useRequestStore } from "@/stores/requestStore";
import { useClientStore } from "@/stores/clientStore";
import { Forms } from "@/interfaces/request.interface";

import MultipleUpload from "../MultipleUpload.vue";
import TextInput from "../TextInput.vue";
import PlusIconCircle from "@/components/icons/PlusIcon.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

const store = useRequestStore();
const clientStore = useClientStore();

const formType = computed(() => store.formType);
const clientTheme = clientStore.theme;

const NEW_DETAIL = {
  detalle_reclamo: "",
  valor_estimado: null,
  imagenes: [],
};
</script>

<template>
  <Transition
    enter-active-class="animate__animated animate__fadeIn animate__delay-1s"
    leave-active-class="animate__animated animate__fadeOut animate__fast"
    mode="out-in"
    appear
  >
    <div v-if="formType === Forms.Benefit">
      <h1 class="title text-base md:text-xl my-3">Subí las imágenes</h1>
      <div class="border-section py-4 md:py-8 px-4 md:px-8 2xl:px-12 mb-9">
        <MultipleUpload name="imagenes" :form-type="formType" />
      </div>
    </div>
  </Transition>
  <Transition
    enter-active-class="animate__animated animate__fadeIn animate__delay-1s"
    leave-active-class="animate__animated animate__fadeOut animate__fast"
    mode="out-in"
    appear
  >
    <div v-if="formType === Forms.Assistance" :class="clientTheme">
      <h1 class="title text-base md:text-xl my-3">
        Completá los datos del equipo
      </h1>
      <div class="mb-11">
        <FieldArray name="equipos" v-slot="{ fields, push, remove }">
          <TransitionGroup name="fade" mode="out-in">
            <fieldset
              v-for="(field, idx) of fields"
              class="border-section py-4 2xl:py-8 px-4 md:px-8 2xl:px-12 mb-9"
              :key="field.key"
            >
              <div class="flex flex-col md:flex-row pt-4 gap-3 md:gap-8">
                <div class="w-full">
                  <TextInput
                    label="Detalle del reclamo"
                    placeholder="Información completa:objecto, marca, modelo, etc."
                    :name="`equipos[${idx}].detalle_reclamo`"
                    :id="`detalle_reclamo_${idx}`"
                    type="text"
                  />
                </div>

                <div class="w-full">
                  <TextInput
                    placeholder="$"
                    label="Valor Estimado"
                    :name="`equipos[${idx}].valor_estimado`"
                    :id="`valor_estimado_${idx}`"
                    type="text"
                  />
                  <p
                    class="text-grayDetails-200 font-montserrat text-[10px] md:text-xs mt-1"
                  >
                    Si desconoce el valor, no completar
                  </p>
                </div>
              </div>

              <MultipleUpload
                :name="`equipos[${idx}].imagenes`"
                labelButton="Hacé click y subí las fotos del objeto reclamado, factura o presupuesto de reparación"
                class="pt-8 pb-4 md:py-6 2xl:py-8"
                :form-type="formType"
              />
              <div class="flex md:mb-3 2xl:mb-8 gap-x-4">
                <button
                  type="button"
                  class="btn btn-salmon btn-transition hover:bg-skin-button-bg-hover flex items-center w-40 md:w-48 2xl:w-52 h-9 md:h-10 2xl:h-11 bg-skin-major"
                  @click="push(NEW_DETAIL)"
                >
                  <PlusIconCircle class="w-5 2xl:w-6 mr-1 md:mx-2" />
                  <p
                    class="font-bold font-montserrat mb-[2px] md:mb-0 text-sm 2xl:text-base"
                  >
                    Agregar otro
                  </p>
                </button>
                <button
                  v-if="fields.length > 1"
                  type="button"
                  class="text-skin-major group"
                  @click="remove(idx)"
                >
                  <div
                    class="border-[1.5px] btn-transition border-skin-major hover:text-white hover:border-transparent hover:bg-skin-button-bg-hover rounded-lg"
                  >
                    <DeleteIcon
                      class="p-1 md:p-2 w-9 2xl:w-11 h-9 md:w-10 md:h-10 2xl:h-11"
                    />
                  </div>
                </button>
              </div>
            </fieldset>
          </TransitionGroup>
        </FieldArray>
      </div>
    </div>
  </Transition>
</template>

<style scoped></style>
