import { axiosInstance as axios } from "./axios.config";

export const signInUserAndAutocompleteRefundForm = async (key: string) => {
  const url = `/refund_preload/?key=${key}`;
  return await axios.get(url);
};

export const submitRefund = async (formData: any, key: string) => {
  const url = `/refund_submit/?key=${key}`;
  return await axios.post(url, formData);
};
