import type { AxiosRequestConfig } from "axios";
import { axiosInstance as axios } from "./axios.config";

export const fetchForm = async (id: number): Promise<{ data: any }> => {
  return await axios.get(`/precarga_solicitud/${id}/`);
};

export const createRequest = async (formData: any): Promise<{ data: any }> => {
  return await axios.post("/create_solicitud/", formData);
};

export const uploadImage = async (
  data: any,
  config: AxiosRequestConfig
): Promise<{ data: object }> => {
  return await axios.post("/imagen_equipo/", data, config);
};

export const deleteImage = async (id: number): Promise<void> => {
  return await axios.delete(`/imagen_equipo/${id}/`);
};
