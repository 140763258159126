<script setup lang="ts">
import { RouterView } from "vue-router";
import { ClientName, useClientStore } from "@/stores/clientStore";
import { useFavicon } from "@vueuse/core";
import { computed, watch, watchEffect } from "vue";
import { useAuthStore } from "./stores/authStore";
import { getToken, isSupported } from "@firebase/messaging";
import { sendTokenFcm } from "./services/notification.services";
import { messaging } from "./firebase";
import { REFUND_PATH, LOGIN_PATH } from "@/constants/pathnames";

import { useNotificationStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import { useRefundStore } from "./stores/refundStore";

const clientStore = useClientStore();
const { setClientName } = clientStore;
const { client } = storeToRefs(clientStore);

const authStore = useAuthStore();
const { automaticLogin } = authStore;

const refundStore = useRefundStore();
const { redirectUserToRefundForm, getCookie } = refundStore;

const notificationStore = useNotificationStore();
const { getNotifications } = notificationStore;

setClientName();

const favicon = computed(() => {
  if (client.value === ClientName.RioUruguaySeguros) {
    return "/faviconRus.svg";
  }
  if (client.value === ClientName.Galicia) {
    return "/faviconGalicia.svg";
  }
  return "/faviconRapiHogar.svg";
});

const deviceType = computed(() => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
    return "tablet";

  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  )
    return "mobile";

  return "desktop";
});

const isLoggedIn = computed(() => {
  return authStore.user && authStore.token;
});

useFavicon(favicon);

watchEffect(async () => {
  await requestFirebaseMessagingToken();

  if (isLoggedIn.value) {
    await getNotifications();
  }
});

watch(
  () => window.location,
  (newValue) => {
    if (newValue.pathname === REFUND_PATH) {
      const searchParam = newValue.search;
      if (searchParam) {
        const urlParams = new URLSearchParams(searchParam);
        if (urlParams.has("key")) {
          const key = urlParams.get("key");
          key && redirectUserToRefundForm({ key });
        }
      } else {
        const key = getCookie("refund-key");
        key && redirectUserToRefundForm({ key });
      }
    }

    if (newValue.pathname === LOGIN_PATH) {
      automaticLogin(newValue.search);
    }
  },
  { immediate: true }
);

async function requestFirebaseMessagingToken() {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const hasFirebaseMessagingSupport = await isSupported();

      if (hasFirebaseMessagingSupport) {
        const token = await getToken(messaging, {
          vapidKey:
            "BDs850fRFwuynM1ylK3VLZldO3g3FdXyXIhrXRWv8EWar-u8Nip7V-5C0na4uepwP6eoYK3U68JpK91Y87O8vDA",
        });

        if (token) {
          try {
            if (isLoggedIn.value) {
              await sendTokenFcm({
                device: deviceType.value,
                token_fcm: token,
              });
            }
          } catch (err: any) {
            const { token_fcm } = err.data ?? {};
            if (token_fcm) return;
            console.log({ err });
          }
        } else {
          // Handle token retrieval error
          console.log("Error: Failed to retrieve Firebase Messaging token");
        }
      } else {
        console.log("Error: This browser does not support Firebase messaging");
      }
    } else {
      console.log("Error: Notification permission denied");
    }
  } catch (error) {
    // handle error
  }
}
</script>

<template>
  <div id="app">
    <RouterView :key="$route.path" />
  </div>
</template>

<style>
@import "@/assets/base.css";
</style>
