<script setup lang="ts">
import { useTitle } from "@vueuse/core";
import { useRouter } from "vue-router";
import { computed, defineAsyncComponent, ref, watchEffect } from "vue";

import { useRoute } from "vue-router";
const route = useRoute();

import type {
  ProductDetail,
  Policy as Poliza,
  Coverage,
} from "@/interfaces/product.interface";
import { updateAlias } from "@/services/product.services";
import { useTelemedicine } from "@/composables/useTelemedicine";
import { useProductStore } from "@/stores/productStore";
import { useClientStore } from "@/stores/clientStore";

import Policy from "../components/dashboard/policy/Policy.vue";
import CoverageList from "../components/dashboard/policy/CoverageList.vue";
import RequestTable from "@/components/dashboard/request/table/RequestTable.vue";
import ProductViewHeader from "../components/dashboard/ProductViewHeader.vue";
import ChevronRightIcon from "../components/icons/ChevronRightIcon.vue";

const HeaderModal = defineAsyncComponent(
  () => import("@/components/dashboard/policy/HeaderModal.vue")
);
const CoverageDetailModal = defineAsyncComponent(
  () => import("@/components/dashboard/policy/CoverageDetailModal.vue")
);

const props = defineProps<{
  id: string;
}>();

const productStore = useProductStore();
const clientStore = useClientStore();
const router = useRouter();

const product = ref({} as ProductDetail);

const policies = computed(() => product.value.polizas);

const clientTheme = clientStore.theme;

watchEffect(() => {
  if (policies.value?.length > 0) {
    if (policies.value.length === 1) {
      const currentPolicy = policies.value[0];
      const indexOfObject = policies.value.indexOf(currentPolicy);
      setActive(currentPolicy, indexOfObject);
      return;
    }
    if (route.query.policyId) {
      const policyId = Number(route.query.policyId);
      const policyIndex = policies.value.findIndex((p) => p.id === policyId);

      if (policyIndex !== -1) {
        setActive(policies.value[policyIndex], policyIndex);
      }
    }
  }
});
const policy = ref({} as Poliza);

const hasTelemedicineCoverage = computed(() => {
  return policy.value?.assistance_coverage.some(
    (coverage) => coverage.is_telemedicine
  );
});

const selectedTab = ref(-1);

const title = computed(() => {
  return policy.value.alias;
});

async function changeAlias(payload: string) {
  const { id } = policy.value;
  const { data } = await updateAlias(id, payload);
  policy.value = data;
  product.value = await productStore.loadProduct(props.id);
}

function handleChange(evt: Event): void {
  const evento = (evt.target as HTMLInputElement).value;

  const newPolicy = policies.value?.find(
    (policy) => policy.id === Number(evento)
  );

  if (newPolicy) {
    policy.value = newPolicy;
    productStore.setCurrentPolicy(newPolicy);
  }
}

function setActive(poliza: Poliza, index: number) {
  selectedTab.value = index;
  policy.value = poliza;
  productStore.setCurrentPolicy(poliza);
}

const pageTitle = computed(() => {
  return `${product.value.title} | ${
    policy.value.alias || policy.value.nro_poliza
  }`;
});

useTitle(pageTitle);

const coverageText = computed(() => {
  if (clientStore.client !== "rapihogar") {
    return "No existen asistencias";
  }
  return "No existen solicitudes realizadas";
});

const coverageModal = ref(false);
const currentCoverage = ref<Coverage>();
const headerModal = ref(false);

const { openTelemedicineModal } = useTelemedicine();

async function showTelemedicineModalFromHeader() {
  if (!policy.value) return;

  const coverage = policy.value.assistance_coverage.find(
    (coverage) => coverage.is_telemedicine
  );

  if (coverage) {
    currentCoverage.value = coverage;
    await openTelemedicineModal(policy.value.id, coverage.id);
    headerModal.value = false;
  }
}

async function showCoverageModal(coverage: Coverage) {
  if (!coverage) return;
  currentCoverage.value = coverage;

  if (coverage.is_telemedicine) {
    if (!policy.value) return;

    await openTelemedicineModal(policy.value.id, coverage.id);
  } else {
    coverageModal.value = true;
  }
}

product.value = await productStore.loadProduct(props.id);
</script>

<template>
  <div
    class="flex flex-col justify-start py-4 px-6 md:py-10 md:px-24 gap-4 mb-10"
    :class="clientTheme"
  >
    <template v-if="product">
      <ProductViewHeader
        :title="product.title"
        :cod_ramo="product.cod_ramo_bco"
        :policy="policy"
        :has-telemedicine-coverage="hasTelemedicineCoverage"
        @openHeaderModal="headerModal = true"
      />

      <div
        class="border-b-2 border-grayDetails-100 hidden md:flex justify-between items-end"
        v-if="policies && policies.length > 1"
      >
        <div class="flex">
          <button
            v-for="(policy, index) of policies"
            :key="policy.id"
            @click="setActive(policy, index)"
            class="flex pb-3 font-medium text-base font-montserrat max-w-max px-8 mb-[-2px] btn-transition"
            :class="[
              selectedTab === index
                ? 'text-skin-highlight font-bold border-skin-highlight border-b-[4px]'
                : 'text-grayDetails-100',
            ]"
          >
            {{ policy.alias }}
          </button>
        </div>
      </div>

      <div class="md:hidden group flex">
        <select
          class="bg-white border w-[75%] border-grayDetails-100 text-grayDetails-400 text-xs rounded-md shadow-sm cursor-pointer py-[10px] font-montserrat"
          @change="handleChange"
        >
          <option value="" disabled>Póliza</option>
          <option
            v-for="(poliza, index) of policies"
            :key="index"
            :value="poliza.id"
            :selected="poliza.id === policy.id"
          >
            {{ poliza.alias }}
          </option>
        </select>
        <div
          class="relative inset-y-0 flex right-[50px] items-center pointer-events-none"
        >
          <ChevronRightIcon
            class="w-[18px] h-[18px] ml-5 text-grayDetails-100 group-active:rotate-90 duration-300"
          />
        </div>
      </div>

      <template v-if="policy">
        <Transition enter-active-class="animate__animated animate__fadeIn">
          <main class="flex flex-col" :key="policy.id">
            <Policy
              :insured="policy.asegurado"
              :policy="policy"
              @change-alias="changeAlias"
              :title="title"
            />

            <CoverageList
              :coverages="policy.assistance_coverage"
              :title="title"
              @open-modal="showCoverageModal"
            />

            <RequestTable :requests="policy.request_list" :title="title" />

            <span
              v-if="policy.request_list?.length === 0"
              class="font-bold mb-2 text-primary py-4 text-center"
            >
              {{ coverageText }}</span
            >
          </main>
        </Transition>
      </template>
    </template>
  </div>

  <HeaderModal
    v-if="headerModal"
    :visible="headerModal"
    @close-modal="headerModal = false"
    @redirect-to-create-request="
      router.push({
        name: 'RequestsCreate',
        query: { policy_id: policy?.id },
      })
    "
    @show-telemedicine-modal="showTelemedicineModalFromHeader"
  />

  <CoverageDetailModal
    v-if="coverageModal"
    :visible="coverageModal"
    :coverage="currentCoverage!"
    @close-modal="coverageModal = false"
  />
</template>
<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
}
</style>
