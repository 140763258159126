<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useClientStore } from "@/stores/clientStore";
import {
  useNotificationStore,
  type Notification,
} from "@/stores/notifications";
import { useFormatDate } from "@/composables/formatDate";

import BellIcon from "../../icons/BellIcon.vue";

import { useRouter } from "vue-router";

const props = defineProps<{
  notifications?: Notification[];
  cursor?: string;
}>();

const isLoading = ref(false);

const notificationStore = useNotificationStore();
const { markNotificationAsRead } = notificationStore;

const emit = defineEmits<{
  (e: "refreshNotifications"): void;
  (e: "markAllNotificationsAsRead"): void;
  (e: "markOneNotificationAsRead", notification: Notification): void;
}>();

const { formatDateTime } = useFormatDate();

const filteredNotifications = ref<Notification[]>();

watchEffect(
  () => {
    if (props.notifications?.length) {
      notificationStatus.value
        ? getAllNotifications()
        : getUnreadNotifications();
    }
  },
  { flush: "post" }
);

const showDropDown = ref(false);

const notificationStatus = ref(true);

const getAllNotifications = async () => {
  notificationStatus.value = true;
  filteredNotifications.value = props.notifications;
};

const getUnreadNotifications = async () => {
  notificationStatus.value = false;
  filteredNotifications.value = props.notifications?.filter(
    (notification) => !notification.read
  );
};

const markAsRead = async (notification: Notification) => {
  isLoading.value = true;
  await markNotificationAsRead(notification);
  isLoading.value = false;
  emit("refreshNotifications");
};

const setAllRead = () => {
  emit("markAllNotificationsAsRead");
};

const areAllNotificationsRead = computed(() => {
  return props.notifications?.every((notification) => notification.read);
});

const router = useRouter();
const redirectToRequestDetail = (notification: Notification) => {
  emit("markOneNotificationAsRead", notification);
  router.push({
    name: "RequestView",
    params: {
      id: notification.request.id,
    },
    query: { nro_gestion: notification.request.nro_gestion },
  });
};

const clientStore = useClientStore();
const clientThemeBg = computed(() => {
  if (clientStore.client === "rus") {
    return "bg-blueRus-100 text-blueRus-100";
  }
  if (clientStore.client === "galicia") {
    return "bg-redGalicia text-redGalicia";
  }
  return "bg-orangeRapihogar text-orangeRapihogar";
});
</script>
<template>
  <article
    class="flex flex-col p-4 md:py-5 2xl:py-6 md:px-9 2xl:px-12 gap-8 md:gap-10 h-full"
  >
    <div class="flex justify-between items-center">
      <div class="flex gap-2 md:gap-3 text-xs md:text-lg">
        <button
          type="button"
          class="chip"
          @click="getAllNotifications"
          :class="[
            clientThemeBg,
            notificationStatus ? 'bg-opacity-10' : 'bg-transparent',
            ,
          ]"
        >
          <p :class="[notificationStatus ? '' : 'text-grayDetails-400']">
            Todas
          </p>
        </button>

        <button
          type="button"
          class="chip"
          @click="getUnreadNotifications"
          :class="[
            clientThemeBg,
            notificationStatus ? 'bg-transparent' : 'bg-opacity-10',
            ,
          ]"
        >
          <p :class="[notificationStatus ? 'text-grayDetails-400' : '']">
            No leídas
          </p>
        </button>
      </div>

      <div class="md:hidden relative">
        <button @click="showDropDown = !showDropDown">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
            />
          </svg>
        </button>

        <transition name="slide-fade">
          <div
            v-if="showDropDown"
            class="absolute right-3 py-2 px-3 bg-white rounded-md w-max shadow-md"
          >
            <button
              type="button"
              :class="[
                areAllNotificationsRead || isLoading
                  ? 'bg-grayDetails-300 text-grayDetails-200'
                  : 'text-skin-major hover:bg-skin-major-light transition duration-300',
              ]"
              class="flex items-center gap-2 rounded-md py-1 px-2"
              :disabled="areAllNotificationsRead || isLoading"
              @click="setAllRead"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3 rotate-12"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
              <span class="text-xs font-medium font-montserrat">
                Marcar todas como leídas
              </span>
            </button>
          </div>
        </transition>
      </div>

      <button
        type="button"
        :class="[
          areAllNotificationsRead || isLoading
            ? 'bg-grayDetails-300 text-grayDetails-200'
            : 'text-skin-major hover:bg-skin-major-light transition duration-300',
        ]"
        class="hidden md:flex items-center gap-2 rounded-md py-1 px-2"
        :disabled="areAllNotificationsRead || isLoading"
        @click="setAllRead"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5 rotate-12"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>

        <span class="text-xs font-medium font-montserrat">
          Marcar todas como leídas
        </span>
      </button>
    </div>

    <div
      v-if="!filteredNotifications?.length"
      class="flex flex-col items-center my-auto"
    >
      <BellIcon
        class="text-grayDetails-100 h-12 w-12 md:w-20 md:h-20 2xl:h-20 2xl:w-20"
      />
      <h4 class="title text-lg md:text-xl">No tenés notificaciones.</h4>
    </div>

    <div
      v-for="notification of filteredNotifications"
      :key="notification.id"
      class="py-3 md:py-4 px-4 md:px-6 flex flex-col gap-2 md:gap-4"
      :class="[notification.read ? '' : 'notification-read', `${cursor}`]"
      @click="redirectToRequestDetail(notification)"
    >
      <p
        class="table-card-title text-base mb-0 md:text-lg text-grayDetails-400"
      >
        {{ notification.title }}
      </p>
      <p class="font-medium text-xs md:text-base">
        {{ notification.body }}
      </p>
      <p class="font-bold text-xs text-grayDetails-200">
        {{ formatDateTime(notification.created_time) }} hs
      </p>

      <div v-if="!notification.read">
        <button
          type="button"
          :class="[
            isLoading
              ? 'bg-grayDetails-300 text-grayDetails-200'
              : 'text-skin-major hover:bg-skin-major-light transition duration-300',
          ]"
          class="flex items-center gap-2 rounded-md py-1 px-2"
          :disabled="isLoading"
          @click.stop.prevent="markAsRead(notification)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-3 h-3 rotate-12"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>

          <span class="text-xs font-medium font-montserrat">
            Marcar como leída</span
          >
        </button>
      </div>
    </div>
  </article>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.5s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.2s;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
