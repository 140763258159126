<script setup lang="ts">
import { useUniqueID } from "@/composables/uniqueID";
import { useFormComponent } from "@/composables/SetupFormComponent";

const props = defineProps({
  placeholderSpace: Boolean,
  label: { type: String, default: "" },
  modelValue: { type: [String, Number], default: "" },
  error: { type: [String], default: "" },
  hint: { type: String, default: "" },
});

const { updateValue } = useFormComponent(props);

const uuid = useUniqueID().getID();
</script>

<template>
  <div class="flex flex-col w-full border-none p-0">
    <div class="flex gap-1">
      <label
        :for="uuid"
        class="title text-grayDetails-400"
        v-if="props.label"
        >{{ props.label }}</label
      >
      <span class="font-medium" v-show="props.hint">{{ hint }}</span>
    </div>
    <input
      class="base-input w-full"
      :class="{ 'pl-9': placeholderSpace, 'border-red-500': props.error }"
      v-bind="{ ...$attrs, onInput: updateValue }"
      :id="uuid"
      :value="props.modelValue"
      :aria-describedby="props.error ? `${uuid}-error` : undefined"
      :aria-invalid="props.error ? true : false"
    />
    <BaseErrorMessage
      class="font-medium text-[15px] 2xl:text-base font-montserrat"
      v-if="props.error"
      :id="`${uuid}-error`"
    >
      {{ props.error }}
    </BaseErrorMessage>
  </div>
</template>

<style scoped></style>
