<script lang="ts">
export default {
  name: "BellIcon",
};
</script>

<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 39.2 39.2"
    xml:space="preserve"
    class="fill-current h-6 w-6 2xl:h-[30px] 2xl:w-[30px]"
  >
    <path
      d="M19.6,37.2c1.8,0,3.2-1.5,3.3-3.3h-6.5C16.4,35.7,17.8,37.2,19.6,37.2z M29.4,27.4v-8.1c0-5-2.6-9.2-7.3-10.3
		V7.9c0.1-1.3-0.9-2.5-2.3-2.6c-1.3-0.1-2.5,0.9-2.6,2.3c0,0.1,0,0.2,0,0.3V9c-4.6,1.1-7.3,5.3-7.3,10.3v8.1l-3.3,3.3v1.6h26v-1.6
		L29.4,27.4z M26.1,29h-13v-9.7c0-4,2.4-7.3,6.5-7.3s6.5,3.3,6.5,7.3V29z"
    />
  </svg>
</template>
