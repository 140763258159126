<script setup lang="ts">
import FooterRus from "@/components/dashboard/FooterRus.vue";
import ErrorMessageIcon from "@/components/icons/ErrorMessageIcon.vue";
import LogoutIcon from "../components/icons/LogoutIcon.vue";
import { useClientStore } from "../stores/clientStore";
import { useAuthStore } from "@/stores/authStore";

const clientStore = useClientStore();
const authStore = useAuthStore();

const clientTheme = clientStore.theme;

const BACK_URL = import.meta.env.VITE_CLIENT_CALLBACK_URL;

const props = defineProps({
  logoutIcon: Boolean,
  statusTitle: {
    type: String,
    default: "¡Ups! Algo salió mal",
  },
  statusMessage: {
    type: String,
    default: "Para acceder nuevamente haz click",
  },
});
</script>

<template>
  <div class="flex justify-center h-full items-center">
    <div
      v-if="authStore.showCloseSesion"
      :class="clientTheme"
      class="flex flex-col justify-center"
    >
      <div
        class="h-[75vh] flex flex-col justify-center items-center gap-6 w-full flex-grow"
      >
        <LogoutIcon
          v-if="logoutIcon"
          class="w-[74px] h-[81px] md:w-[123px] md:h-[136px]"
        ></LogoutIcon>
        <ErrorMessageIcon
          v-else
          class="w-[74px] h-[81px] md:w-[123px] md:h-[136px] text-skin-major"
        ></ErrorMessageIcon>
        <h1 class="font-bold text-lg md:text-2xl font-montserrat">
          {{ props.statusTitle }}
        </h1>
        <p class="text-xs text-center max-w-xl md:text-base font-montserrat">
          {{ props.statusMessage }}
          <a
            :href="BACK_URL"
            class="text-link font-bold"
            rel="noopener noreferrer"
            >aquí</a
          >
        </p>
      </div>
      <FooterRus v-if="logoutIcon" class="mt-auto mb-4" />
    </div>
    <div v-if="authStore.error">
      <h1 class="text-primary">
        Tuvimos un inconveniente. Por favor comunicate al teléfono
        <span class="title text-link">351 5760609</span> o por whatsapp al
        <span class="title text-link">11 5996 3708</span> .
      </h1>
    </div>
  </div>
</template>

<style scoped></style>
