<script setup lang="ts">
import { computed } from "vue";
import { useFormatDate } from "@/composables/formatDate";

import type { Request } from "@/interfaces/product.interface";

import { EyeIcon } from "@heroicons/vue/outline";
import { RouterLink } from "vue-router";

const props = defineProps<{
  request: Request;
  requestPath?: boolean;
}>();

const statusColor = computed<string>(() => {
  const { estado_actual } = props.request;
  if (estado_actual === "EN CURSO") return "text-yellow-500";
  if (estado_actual === "REALIZADO") return "text-green-500";
  if (estado_actual === "CANCELADO") return "text-red-500";

  return "";
});

const { formatDate } = useFormatDate();
</script>

<template>
  <div class="text-xs flex flex-col font-montserrat border-section px-5 py-4">
    <div class="grid grid-cols-2 gap-x-10 gap-4">
      <div class="flex flex-col gap-y-1">
        <h3 class="text-grayDetails-200 font-bold">Producto</h3>
        <p class="text-grayDetails-400 font-medium">
          {{ request.producto?.title }}
        </p>
      </div>

      <div class="flex flex-col gap-y-1">
        <h3 class="text-grayDetails-200 font-bold">Póliza</h3>
        <p class="text-grayDetails-400 font-medium">
          {{ request.poliza }}
        </p>
      </div>

      <div class="flex flex-col gap-y-1">
        <h3 class="text-grayDetails-200 font-bold">Fecha de inicio</h3>
        <p class="text-grayDetails-400 font-medium">
          {{ formatDate(request.fecha_inicio) }}
        </p>
      </div>

      <div class="flex flex-col gap-y-1">
        <h3 class="text-grayDetails-200 font-bold">Estado</h3>
        <p
          :class="statusColor"
          class="capitalize text-grayDetails-400 font-bold"
        >
          {{ request.estado_actual.toLowerCase() }}
        </p>
      </div>
    </div>

    <hr class="my-4" />

    <RouterLink
      :to="{
        name: 'RequestView',
        params: { id: request.id },
        query: { nro_gestion: request.nro_gestion },
      }"
      class="text-grayDetails-200 flex self-center"
    >
      <EyeIcon
        class="w-4 md:w-[24px] cursor-pointer md:h-[24px] hover:text-skin-major"
      />
      <p class="font-bold ml-2">Ver detalle</p>
    </RouterLink>
  </div>
</template>
