<script setup lang="ts">
import { ref, watchEffect, computed } from "vue";
import type { RequestDetail } from "@/interfaces/product.interface";
import { useFormatDate } from "@/composables/formatDate";
import CheckIcon from "@/components/icons/CheckIcon.vue";

const { formatDateTime } = useFormatDate();

const props = defineProps<{
  milestones?: RequestDetail["hitos"];
}>();

const filteredMilestones = ref<RequestDetail["hitos"]>();

watchEffect(
  () => {
    if (props.milestones?.length) {
      allMilestones.value ? getAllMilestones() : getImportantMilestones();
    }
  },
  { flush: "post" }
);

const allMilestones = ref(false);

const getAllMilestones = async () => {
  allMilestones.value = true;
  filteredMilestones.value = props.milestones;
};

const getImportantMilestones = async () => {
  allMilestones.value = false;
  filteredMilestones.value = props.milestones?.filter(
    (milestone) => milestone.important
  );
};

// const importantMilestones = computed(() => {
//   return props.milestones?.filter((milestone) => milestone.important);
// });

// const allMilestonesTest = computed(() => {
//   return props.milestones;
// });

const getLastCreatedDate = computed(() => {
  const date = props.milestones
    ?.filter((milestone) => milestone.created_time != null)
    .reduce((max, obj) => (max.created_time > obj.created_time ? max : obj));
  return date?.created_time;
});
</script>
<template>
  <div>
    <h4 class="title text-base md:text-xl mb-1 md:mb-3">Seguimiento</h4>
    <div class="flex flex-col justify-center">
      <article
        class="border-section flex flex-col p-4 md:py-5 2xl:py-6 md:px-4 2xl:px-4 gap-4 md:h-[29rem] md:overflow-y-auto"
      >
        <ul>
          <li
            v-for="({ description, created_time }, index) of filteredMilestones"
            :key="index"
            class="flex gap-x-4 items-center mb-10 md:mb-5"
          >
            <div class="h-full">
              <div class="h-10 bg-white flex justify-center items-center">
                <div
                  class="rounded-full border-solid border-2 w-7 p-[3px] text-white"
                  :class="[
                    created_time
                      ? 'border-greenStatus-200 bg-greenStatus-200'
                      : 'border-grayDetails-100 bg-white',
                  ]"
                >
                  <CheckIcon />
                </div>
              </div>
              <span
                class="vertical-line border-l-greenStatus-200 border-transparent border-solid border-2 h-[95px] md:h-[112px] lg:h-[90px] xl:h-[75px] absolute left-1/2"
                :class="[
                  created_time ? '' : 'border-dashed border-l-grayDetails-100',
                ]"
              ></span>
            </div>
            <div
              class="flex flex-col gap-y-2 w-full py-4 px-6"
              :class="[
                created_time === getLastCreatedDate ? 'actual-request' : '',
              ]"
            >
              <p
                class="text-base title"
                :class="[created_time ? '' : 'text-grayDetails-200']"
              >
                {{ description }}
              </p>
              <p
                v-if="created_time"
                class="text-xs md:text-sm font-montserrat font-medium text-grayDetails-200"
              >
                {{ formatDateTime(created_time) }} hs
              </p>
            </div>
          </li>
          <!-- <li
            v-if="index === 0"
            class="text-greenStatus-200 text-xs font-montserrat font-medium"
          >
            Estado actual del pedido
          </li> -->
        </ul>
      </article>
      <button
        v-show="allMilestones && milestones?.length > 4"
        @click="getImportantMilestones"
        class="mt-4 hover:bg-skin-major-light transition duration-300 py-3 rounded-md"
      >
        <span class="text-skin-major font-bold font-montserrat text-base"
          >Ver menos</span
        >
      </button>
      <button
        v-show="!allMilestones && milestones?.length > 4"
        @click="getAllMilestones"
        class="mt-4 hover:bg-skin-major-light transition duration-300 py-3 rounded-md hover:cursor-pointer"
      >
        <span class="text-skin-major font-bold font-montserrat text-base"
          >Ver seguimiento completo
        </span>
      </button>
    </div>
  </div>
</template>

<style scoped>
li:last-of-type .vertical-line {
  display: none;
}
</style>
