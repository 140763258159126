<script lang="ts">
export default {
  name: "PetIcon",
};
</script>

<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 139.8 139.8"
    class="fill-current"
    xml:space="preserve"
  >
    <g>
      <path
        d="M88.7,73.1c-5.1-4.7-11.3-7.2-18.4-7.4l0,0c0,0-0.1,0-0.1,0c-7.2,0-13.9,2.6-19,7.4c-4.3,4-8.5,8-12.7,12
		c-3.1,2.9-6.1,5.8-9.2,8.7c-5,4.7-7,10.5-6.1,17.3c1.4,10.6,10.7,18.2,21.2,17.3c3.6-0.3,7.5-1.1,12.5-2.5
		c7.8-2.1,13.7-2.7,19.4-1.7c3.1,0.5,6.2,1.3,9.2,2c1.5,0.4,2.9,0.7,4.4,1.1l0.2,0.1c2.5,0.6,5,1.1,7.6,1.1c1,0,2-0.1,3-0.3
		c7.7-1.5,13.1-6.3,15.5-14c2.4-7.7,0.6-14.6-5.1-20.1C104.2,87.7,97.2,81,88.7,73.1z M108.5,113.2c-1.8,4.7-6.2,7.8-11.1,7.8
		c-0.1,0-0.2,0-0.3,0c-2,0-4.1-0.6-6.1-1.1l-1.1-0.3c-0.9-0.2-1.8-0.4-2.6-0.6c-5.1-1.3-10.4-2.6-15.9-2.7
		c-5.9-0.1-11.2,1.2-17.6,2.8l-0.6,0.1c-3.9,1-7.6,2-11.4,1.7c-5.2-0.5-8.8-3.2-10.7-8c-1.9-5-1-9.6,2.6-13.1
		c6.6-6.4,13.7-13.2,23.2-21.8c3.4-3.1,7.8-4.7,13.3-4.7c5,0,9.5,1.8,13.1,5.1c7.2,6.7,14.9,13.9,22.5,21.3
		C109.6,103.4,110.5,107.9,108.5,113.2z"
      />
      <path
        d="M50.4,59.3C50.4,59.3,50.4,59.3,50.4,59.3c4.2,0,8.1-2,11.2-5.7c3.7-4.6,5.5-10.1,5.7-17.4l0-0.1l0-0.1
		c0-0.3,0-0.6-0.1-0.9c-0.1-0.9-0.1-1.9-0.3-3c-0.9-6.4-3-11-6.7-14.5c-5.5-5.2-13.3-5.4-18.9-0.5c-1.6,1.4-3,3.2-4.4,5.6
		c-1.9,3.2-2.9,6.9-3.2,11.7c-0.5,7.5,1.4,13.9,5.6,19.1C42.3,57.3,46.2,59.3,50.4,59.3z M41.2,36.4c0.1-4.3,0.8-7.6,2.4-10.4
		c1.9-3.4,4.1-5,6.9-5c0,0,0,0,0,0c2.9,0,5.2,1.7,6.8,5c3.3,6.9,3.3,13.9,0,20.7c-1.6,3.3-3.9,4.9-6.8,5c0,0,0,0,0,0
		c-2.8,0-5-1.6-6.9-5C42,44,41.3,40.7,41.2,36.4z"
      />
      <path
        d="M89.3,59.3C89.3,59.3,89.4,59.3,89.3,59.3c4.2,0,8.3-2.1,11.3-6c3.2-4,5-8.8,5.3-14.3c0.5-7.7-1.1-14-5-19.1
		c-3-4-7.2-6.2-11.7-6.2c0,0,0,0,0,0c-4.5,0-8.8,2.3-11.8,6.3c-3.1,4.1-4.7,9.2-5,15.6l0,0.1c0,7.1,1.9,13.2,5.4,17.7
		C81.1,57.2,85.1,59.3,89.3,59.3z M82.4,26.2c0.5-1,1.2-2,2.1-2.9c1.5-1.5,3.1-2.3,4.9-2.3c0,0,0,0,0,0c1.7,0,3.4,0.8,4.9,2.3
		c3,3,3.8,7.1,4.2,10.3c0.1,0.6,0.1,1.3,0.1,1.8c0,0.2,0,0.4,0,0.6c0,4.7-0.7,7.8-2.3,10.4c-1,1.7-2.3,3.2-3.7,4.2
		c-2.3,1.7-4.9,1.6-7.4-0.4c-2.6-2.1-4-4.9-4.8-9.3C79.5,35.4,80.1,30.6,82.4,26.2z"
      />
      <path
        d="M31.6,75.4c1.1-2.6,1.7-5.4,1.8-8.9l0-0.1l0-0.1c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.9-0.1-1.9-0.3-2.8c-1-7.1-3.7-12.5-8.3-16.4
		c-3.3-2.8-7.1-3.9-10.7-3c-3.6,0.9-6.7,3.6-8.3,7.5c-1.8,4.2-2.2,8.9-1.1,14.3C5.6,71,8,75.8,11.7,79.4c2.6,2.5,5.8,3.9,8.9,3.9
		c0.7,0,1.4-0.1,2.1-0.2C26.6,82.3,29.8,79.5,31.6,75.4z M25.8,65.1c0,3.7-0.4,5.9-1.4,7.9c-0.8,1.5-1.8,2.5-3,2.7
		c-1.2,0.3-2.5-0.1-3.8-1.2c-2.5-1.9-4-4.6-5.3-8.9c-1.1-4-1.3-7.5-0.5-10.7c0.7-2.9,1.9-4.3,3.8-4.8c0.3-0.1,0.7-0.1,1-0.1
		c1.6,0,3,0.9,4.5,2.6C25,57.3,25.7,62.5,25.8,65.1z"
      />
      <path
        d="M132.5,47.5c-3.6-5-9.5-6.4-14.8-3.4c-1.7,1-3.3,2.3-4.6,3.8c-4.2,4.8-6.4,10.8-6.7,18.5l0,0.1l0,0.1
		c0.1,0.6,0.1,1.1,0.2,1.7c0.1,1.3,0.3,2.6,0.6,3.9c0.8,3.6,2.5,6.4,5,8.5c2.1,1.7,4.5,2.5,7,2.5c3.5,0,7.1-1.7,9.9-4.9
		c4.5-5,6.7-11.2,6.8-19C136,54.7,134.9,50.8,132.5,47.5z M124.7,71.8c-0.9,1.3-2.2,2.5-3.5,3.4c-1.9,1.2-3.9,0.7-5.3-1.2
		c-1.6-2.2-1.9-5-1.9-7.6c0.2-5.3,1.4-9.4,3.9-12.7c0.9-1.2,2.1-2.2,3.4-3c1.1-0.6,2.3-0.8,3.3-0.5c1,0.3,1.8,1,2.3,2.1
		c0.8,1.5,1.3,3.5,1.5,5.4C128.8,63,127.6,67.7,124.7,71.8z"
      />
    </g>
  </svg>
</template>

<style scoped></style>
