<script setup lang="ts">
import { ref, computed } from "vue";
import { RouterLink } from "vue-router";
import { useFormatDate } from "@/composables/formatDate";
import Modal from "@/components/Modal.vue";
import {
  type Notification,
  useNotificationStore,
} from "@/stores/notifications";
import { useClientStore } from "@/stores/clientStore";

const props = defineProps<{
  notification: Notification;
}>();

const clientStore = useClientStore();
const notificationStore = useNotificationStore();
const { markNotificationAsRead, getNotifications } = notificationStore;

const showModal = ref(false);
const closeIcon = true;

async function openModal(notification: Notification) {
  showModal.value = true;
  await markNotificationAsRead(notification);
  await getNotifications();
}

async function closeModal() {
  showModal.value = false;
}

const { formatDateTime } = useFormatDate();

const btnText = computed(() => {
  if (clientStore.client !== "rapihogar") {
    return "Ver detalle de la asistencia";
  }
  return "Ver detalle de la solicitud";
});
</script>

<template>
  <div class="mx-2 my-3 cursor-pointer" @click="openModal(notification)">
    <h3 class="font-semibold text-[15px] text-grayDetails-400">
      {{ notification.request.nro_gestion }} -
      {{ notification.request.tipo_pedido }}
      <!-- Solicitud {{ notification.request_state?.toLowerCase() }} -->
    </h3>
    <p class="line-limit text-xs font-montserrat font-medium leading-5">
      {{ notification.body }}
      <!-- N° DE GESTIÓN {{ notification.request.nro_gestion }} -->
    </p>
    <p class="text-[10px] text-skin-major font-bold">
      {{ formatDateTime(notification.created_time) }} hs
    </p>
  </div>

  <Modal :isOpen="showModal" :closeIcon="closeIcon" @close-modal="closeModal">
    <template #header>
      <div class="mt-1 max-w-2xl">
        <h1
          class="text-grayDetails-400 text-[17px] font-montserrat font-bold mb-4"
        >
          {{ notification.request.nro_gestion }} -
          {{ notification.request.tipo_pedido }}
        </h1>
      </div>
    </template>
    <template #body>
      <div class="max-w-2xl">
        <p
          class="text-grayDetails-400 text-[15px] font-medium leading-relaxed font-montserrat mb-3"
        >
          {{ notification.body }}
        </p>
        <p class="text-xs text-grayDetails-200 font-montserrat font-bold">
          {{ formatDateTime(notification.created_time) }} hs
        </p>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-col items-center max-w-2xl">
        <RouterLink
          :to="{
            name: 'RequestView',
            params: {
              id: notification.request.id,
            },
            query: { nro_gestion: notification.request.nro_gestion },
          }"
        >
          <button
            @click="closeModal"
            class="btn btn-transition btn-salmon px-10 py-3 bg-skin-major hover:bg-skin-button-bg-hover"
          >
            {{ btnText }}
          </button>
        </RouterLink>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.line-limit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
