import { onMounted, onUnmounted, ref } from "vue";

export const useMobileButton = () => {
  const mobileBtnLabel = ref<HTMLParagraphElement | null>(null);

  const makeBtnVisible = () => {
    if (mobileBtnLabel.value) {
      if (window.scrollY < 10) {
        mobileBtnLabel.value.classList.add("width-button");
        mobileBtnLabel.value.classList.remove("width-scroll");
        if (
          import.meta.env.VITE_CLIENT_NAME === "rapihogar" ||
          import.meta.env.VITE_CLIENT_NAME === ""
        ) {
          return (mobileBtnLabel.value.innerHTML = `<p class='font-bold font-montserrat text-xs mr-2'>Nueva solicitud</p>`);
        }
        return (mobileBtnLabel.value.innerHTML = `<p class='font-bold font-montserrat text-xs mr-2'>Nueva asistencia</p>`);
      }
      if (window.scrollY > 10) {
        mobileBtnLabel.value.classList.remove("mr-2");
        mobileBtnLabel.value.classList.add("width-scroll");
        mobileBtnLabel.value.innerHTML = "";
      }
    }
  };

  onMounted(() => window.addEventListener("scroll", makeBtnVisible));
  onUnmounted(() => window.removeEventListener("scroll", makeBtnVisible));

  return { mobileBtnLabel };
};
