import { axiosInstance as axios } from "./axios.config";
import type { Policy } from "../interfaces/product.interface";

import type {
  Product,
  ProductDetail,
  Request,
  RequestDetail,
} from "@/interfaces/product.interface";

export const fetchProducts = async (): Promise<{ data: Product[] }> => {
  return axios.get("/product/");
};

export const fetchProductByID = async (
  id: string
): Promise<{ data: ProductDetail }> => {
  return axios.get(`/product_detail/${id}/`);
};

export const fetchRequests = async (): Promise<{ data: Request[] }> => {
  return axios.get("/solicitud_list/");
};

export const fetchPolicies = async (): Promise<{ data: any[] }> => {
  return axios.get("/policy/");
};

export const fetchRequestDetail = async (
  id: string | number
): Promise<{ data: RequestDetail }> => {
  return axios.get(`/solicitud_detail/${id}/`);
};

export const downloadPolicy = async (id: number): Promise<{ data: string }> => {
  return axios.get(`/download_poliza/${id}/`, { responseType: "blob" });
};

export const updateAlias = async (
  id: number,
  payload: string
): Promise<{ data: Policy }> => {
  return axios.patch(`/update_poliza_alias/${id}/`, { alias: payload });
};
